<template>
  <div>
  </div>
</template>

<script>
export default {

  name: "VoiceMessage",
  data(){
    return{
      audio:null
    }
  },
}
</script>

<style scoped>

</style>