<template>
  <v-row class="ticket-card" style="overflow:hidden; min-height: 225px;" :class="{'soldOut': ticket.soldOut}" elevation="2">

    <v-col style="position: relative;overflow: hidden" class="pa-0 custom-border-radius col-md-9 col-7 elevation-2">
      <v-img style="width:100%;position:relative;top:0;left:0;height:16px;" :src="require('@/assets/CTA_V2 1.jpg')"></v-img>
      <div class="pa-7 pb-15 d-flex flex-column justify-space-between" style="height: calc(100% - 16px);">
    <h2 class="ticket-title">
      {{ ticket.name }}
    </h2>
    <div class="ticked-info">
      <div class="hidden-xs-only additional-info" v-html="ticket.additionalInformation"></div>
      <v-dialog v-model="showMobileDescription">
        <template #activator="{attrs,on}">
          <v-btn class=" hidden-sm-and-up pa-0 text-lowercase" v-bind="attrs" v-on="on" plain small>open description</v-btn>
        </template>
        <v-card>
          <v-card-text class="pa-3" v-html="ticket.additionalInformation">

          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
      <div class="">
        <v-chip v-if="ticket.soldOut" color="red">Sold out!</v-chip>
      <div v-else-if="isAlreadyInCart!=-1">
        <v-btn small :loading="loading" color="primary" @click="removeOne"><v-icon small>mdi-minus</v-icon></v-btn>
        <span class="pa-5" small color="primary" v-text="isAlreadyInCart"></span>
        <v-btn small :loading="loading" color="primary" @click="addToCart"><v-icon small>mdi-plus</v-icon></v-btn>
      </div>

      <v-btn :loading="loading" large v-else color="accent" style="position:absolute;bottom:0;border-radius: 10px 10px 0 0" @click="addToCart">Add to Cart</v-btn>
      </div>
      </div>
    </v-col>
<v-col style="position: relative;overflow: hidden" class="custom-border-radius pa-0 col-md-3 col-5 elevation-2">
  <v-img style="width:100%;position:relative;top:0;left:0;height:16px" :src="require('@/assets/CTA_V2 1.jpg')"></v-img>
  <div style="position:absolute; left:-2px;top:50%;transform: translateY(-50%);height:85%;width:4px;border-left:3px dotted grey;"></div>
  <div>
  <div class="ticket-price pa-5">
    <p class="price-text">{{ ticket.price }} {{ ticket.currency }}</p>
    <p class="vat-text">incl. 19% VAT</p>
  </div>
  </div>
</v-col>

  </v-row>
</template>

<script>
export default {
  name: "SingleTicketToBuy",
  props: {
    ticket: Object
  },
  data(){
    return{
      showMobileDescription:false,
      loading:false
    }
  },
  computed:{
    isAlreadyInCart(){
      let self=this;
      if(this.ticket && this.cart.findIndex(x => x.product.uid === self.ticket.uid) !=-1) return this.cart[this.cart.findIndex(x => x.product.uid === self.ticket.uid)]?.amount;
      else return -1;
    },
    cart(){
      return this.$store.state.cart.cart;
    },
  currentUser(){
      return this.$store.state.auth.user;
  }
  },
  methods: {
    addToCart() {
      this.loading=true;
      console.log("Amount",this.isAlreadyInCart);
      if(this.currentUser)this.$store.dispatch("cart/addToCart", this.ticket);
      else{
        if(this.isAlreadyInCart>0) this.$store.dispatch("cart/addOneAnonym",{amount:this.isAlreadyInCart, product:this.ticket});
        else this.$store.dispatch("cart/addToCartAnonym",{amount:0, product:this.ticket});
      }
      this.loading=false;
    },
    async removeOne() {
      this.loading = true;
      if (this.isAlreadyInCart > 1) {
        if(this.currentUser) this.$store.dispatch("cart/removeOne", {product: this.ticket});
        else this.$store.dispatch("cart/removeOneAnonym",{amount: this.isAlreadyInCart,product:this.ticket});
      }
      else if (this.isAlreadyInCart <= 1 && this.isAlreadyInCart != -1) {
        await this.removeFromCart();
      }
      this.loading = false;
    },
    removeFromCart(){
      this.loading = true;
      if(this.currentUser) this.$store.dispatch("cart/removeFromCart",{product:this.ticket});
      else this.$store.dispatch("cart/removeFromCartAnonym",{amount:this.isAlreadyInCart,product:this.ticket});
      this.loading=false;
    }
  }
};
</script>

<style scoped>
.ticket-card {
  padding:15px;
  margin-bottom: 20px;
}
.soldOut{
  opacity: 0.7;
}

.ticket-title {
  font-size: 2em;
  font-weight: bold;
}

.ticket-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.ticket-price {
  text-align: right;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.price-text {
  font-size: 2.7rem;
  font-weight: bold;
  margin-bottom: 0px;
}

.vat-text {
  font-size: 12px;
  color: #666;
}

.additional-info {
  margin-top: 10px;
}

.v-btn {
  text-transform: uppercase;
}
</style>
