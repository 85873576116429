import { render, staticRenderFns } from "./DownloadPressOfUser.vue?vue&type=template&id=74aa3315&scoped=true"
import script from "./DownloadPressOfUser.vue?vue&type=script&lang=js"
export * from "./DownloadPressOfUser.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "74aa3315",
  null
  
)

export default component.exports