import ApiService from "@/services/api.service";

export async function createEvent(event){
    let response = await ApiService.post("event/create",event);
    return response;
}
export async function deleteEvent(id){
    let response = await ApiService.get("event/delete/"+id);
    console.log(response);
    return response.data;
}
export async function updateEvent(event){
    let response = await ApiService.post("event/update",event);
    return response;
}

export async function shareEvent(eventId,idsToShare){
    let response = await ApiService.post("event/share/"+eventId,{ids:idsToShare});
    return response.data;
}

export async function changeStateOfEvent(eventId,state){
    let response = await ApiService.get("event/state/change/"+eventId+"/"+state);
    return response.data;
}

export async function getEvents(page){
    let response = await ApiService.get("event/get/events/"+page);
    return response.data;
}
export async function getSpecificEvents(ids){
    let response = await ApiService.post("event/new/specificEvents",{ids:ids});
    return response.data;
}

export async function addArtist(eventId,artistId){
    let response = await ApiService.post("event/artists/add",{eventId:eventId,artistId:artistId})
    return response.data;
}
export async function removeArtist(eventId,artistId){
    let response = await ApiService.post("event/artists/remove",{eventId:eventId,artistId:artistId})
    return response.data;
}
export async function getAllEventsGroupedByDate(dates){
    let response = await ApiService.post("event/get/all/dates",dates);
    return response.data;
}
export async function getAllEventsGroupedByDateOfCurrentYear(){
    let response = await ApiService.getUnauthorized("event/get/all/year");
    return response.data;
}
export async function getAllEventsByDate(date){
    let response = await ApiService.post("event/getByDate",{date:date});
    return response.data;
}
export async function getEventWithArtistsById(id){
    let response = await ApiService.getUnauthorized("event/get/single/"+id);
    return response.data;
}
export async function getAllMyEvents(){
    let response = await ApiService.get("event/my/all");
    return response.data;
}

export async function getEventsArtistParticipates(userId){
    let response= await ApiService.get("event/get/artist/"+userId);
    return response.data;
}