<template>
  <div>
<SingleTicket v-for="ticket of tickets" :ticket="ticket" :key="ticket.uid"/>
    <div v-if="tickets.length<=0">
      You have no tickets.
    </div>
  </div>
</template>

<script>
import SingleTicket from "@/components/ticket/SingleTicket";
import {getMyTickets} from "@/services/ticket.service";
export default {
  name: "TicketList",
  components: {SingleTicket},
  data(){
    return{
      tickets:[]
    }
  },
  created() {
    this.getTickets();
  },
  methods:{
    async getTickets() {
      this.tickets = await getMyTickets();
      if(this.tickets && this.tickets.length>0) this.$store.dispatch("auth/addRole","ROLE_TICKET_24");
    }
  }
}
</script>

<style scoped>

</style>