<template>
  <div  style="z-index:2" v-if="currentUser">
    <v-btn min-width="170px" x-small v-if="followed" color="primary" @click.prevent="unfollow" :icon="icon"><v-icon small class="mr-1">mdi-heart</v-icon>you are following</v-btn>
    <v-btn min-width="170px" x-small v-else dark @click.prevent="follow" :icon="icon"><v-icon small class="mr-1">mdi-heart-outline</v-icon> <span v-if="!icon">Follow</span></v-btn>
  </div>
</template>

<script>
export default {
  name: "FollowButton",
  props: {
    icon: Boolean,
    userId:Number
  },
  computed:{
    currentUser(){
      return this.$store.state.auth.user;
    },
    followed(){
      if(!this.currentUser || !this.userId){
        return false;
      }else if(this.currentUser.followedUsers.includes(this.userId)){
        return true;
      }else return false;
    }
  },
  methods:{
    follow(){
      this.$store.dispatch("auth/follow",this.userId);
    },
    unfollow(){
      console.log("hi")
        this.$store.dispatch("auth/unfollow",this.userId);
    }
  }
}
</script>

<style scoped>

</style>