<template>

  <v-row style="height:100vh">
    <v-col style="min-height: 100vh;position: relative" class="col-12 col-md-6 align-content-center justify-center">
      <v-img :src="require('@/assets/Logo_b_transparent.png')" style="opacity: 0.02;position:absolute;top:0;right:0;transform: translateX(50%) translateY(25%)" width="800" height="800"/>

      <div class="mx-auto px-5" style="max-width: 400px">
        <v-img :src="require('@/assets/logo2023_b-150x54.png.webp')" height="50" width="150"></v-img>
      <h1>Log in to your account</h1>
      <h4 class="mb-5 font-weight-regular">Welcome back! Select method to log in.</h4>
        <v-btn height="50px" style="border-width:2px;-webkit-box-shadow: 0px 10px 40px -10px rgba(0,0,0,0.1);
-moz-box-shadow: 0px 10px 20px -10px rgba(0,0,0,0.1);
box-shadow: 0px 10px 20px -10px rgba(0,0,0,0.1);border-color:#EEEEEE;border-radius: 50px" outlined class="mx-auto my-1 align-self-center" :loading="loading" width="100%" :href="'https://bdme.auth.eu-central-1.amazoncognito.com/login?client_id=6fb837a28n4589dkr8r5c9n6vb&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri='+frontendhost+'/login'">
          <span>Google / Facebook</span>
        </v-btn>
        <div class="mt-5 mb-2 d-flex align-center" style="gap:20px">
          <v-divider></v-divider><span class="text--disabled">Or continue with email</span><v-divider/>
        </div>
    <v-form ref="form" class="d-flex justify-center flex-wrap" v-model="valid">
      <v-text-field
          hide-details
          solo
          rounded
          class="custom-login-input mb-4"
          autocomplete="username"
          autofocus
          style="width:200px;"
          label="Email Address"
          type="email"
          v-model="user.email"
          background-color="#FAFAFA"
          required
      />
      <v-text-field label="Password"
                    hide-details
                    v-model="user.password"
                    style="width:100%;"
                    autocomplete="current-password"
                    solo
                    rounded
                    background-color="#FAFAFA"
                    class="custom-login-input mb-4"

                    @keypress.enter="handleLogin"
                    :rules="passwordRules"
                    type="password"
                    required
      />

      <v-btn height="40px" color="accent" block class="mx-auto align-self-center" style="border-width:2px;-webkit-box-shadow: 0px 10px 40px -10px rgba(0,0,0,0.1);
-moz-box-shadow: 0px 10px 20px -10px rgba(0,0,0,0.1);
box-shadow: 0px 10px 20px -10px rgba(0,0,0,0.1);border-color:#EEEEEE;border-radius: 50px" :loading="loading" width="100px"  @click="handleLogin">
        <span>Login</span>
      </v-btn>

      <v-alert type="error" class="ma-3" v-if="message" dismissible color="red">{{message}}</v-alert>
    </v-form>
        <v-btn class="my-3 float-right" plain small href="/u/reset/password/request">Forgot Password?</v-btn>
        <br/><div class="mx-auto mt-5 d-flex justify-center align-center" style="width:100%;font-size: 0.8rem;">Don't have an account? <v-btn color="accent" text class="text-lowercase" plain @click="$router.push('/register')">Create an account</v-btn></div>
      </div>

    </v-col>
    <v-col style="height: 100vh" class="primary col-12 col-md-6 ">
      <v-carousel
          dark
          height="80vh"

          hide-delimiter-background
          show-arrows-on-hover
      >
        <v-carousel-item
        >
          <div style="position: absolute;top:50%;left:50%;transform: translateY(-50%) translateX(-50%)">
          <v-img class="mb-5 mx-auto" width="400px" height="400px" :src="require('@/assets/@janisreichert__-2 (1).jpg')"></v-img>
          <h3 class="text-center" style="color:white;">Build your network.</h3>
          <h5 class="text-center" style="color:white;">Connect with people all over the world.</h5>
          </div>
        </v-carousel-item>
        <v-carousel-item
        >
          <div style="position: absolute;top:50%;left:50%;transform: translateY(-50%) translateX(-50%)">
          <v-img class="mb-5 mx-auto" width="400px" height="400px" :src="require('@/assets/@janisreichert_Generic-512.jpg')"></v-img>
          <h3 class="text-center" style="color:white;">Get the full experience.</h3>
          <h5 class="text-center" style="color:white;">Create your own timetable. Chat with others.</h5>
          </div>
        </v-carousel-item>
      </v-carousel>
    </v-col>
  </v-row>



</template>

<script>
import {getCognitoToken, loginWithCognito} from "@/services/cognito.service";
import Notification from "@/models/notification";

export default {
  name: 'LoginView',
  data() {
    return {

      valid: true,
      user: {
        email:'',
        password:''
      },
      message: '',
      loading:false,
      username:'',
      usernameRules:[
        v => !!v || this.$t('login.nameRequired'),
        v => (v && v.length <= 20) || this.$t('login.nameLength'),
      ],
      password:'',
      confirmPassword:"",
      passwordRules:
          [
            v => !!v || this.$t('login.passwordRequired'),
          ]

    };
  },
  computed: {
    frontendhost(){
      return process.env.VUE_APP_FRONTHOST;
    },
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    }
  },
  created() {
    //console.log("Der COde ist: "+this.$route.query.code);
    if (this.loggedIn) {
      this.$router.push('/profile');
    } else if (this.$route.query.code) {
      console.log("Client is logging in with Cognito");
      const promise = getCognitoToken(this.$route.query.code)
          promise.then(tokenObject => {
            if(!tokenObject) console.log("GetCognito TOken failed")
        this.$store.state.loading.loginCognitoProcess.tokenObject = tokenObject;
        console.log("Token: "+tokenObject);
        if (tokenObject.extraEmail) {
          loginWithCognito(tokenObject);
        } else {
          this.$store.dispatch("notificationsStore/add", new Notification("warning", "Please provide your email", false))
        }
      });

    }
  },
  methods: {

    handleLogin() {
      if(!this.valid) return;
      this.loading = true;
      /**
       this.$validator.validateAll().then(isValid => {
                    if (!isValid) {
                        this.loading = false;
                        return;
                    }
       **/
      if (this.user.email && this.user.password) {
        this.$store.dispatch('auth/login', this.user).then(
            () => {
              this.$router.push('/profile');
            },
            () => {
              this.loading = false;
              this.message = "You entered the wrong email or password"
            }
        );
      }

    }
  }
};
</script>
<style>

</style>