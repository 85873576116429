<template>
<v-dialog content-class="fixed-dialog" v-if="!hideEmpty || totalItems>0" max-width="400px" scrollable v-model="show">
  <template #activator="{attrs,on}">
    <v-btn :icon="!fab" :class="className" class="ma-5" :fab="fab" v-on="on" v-bind="attrs">
      <v-icon>mdi-cart</v-icon>
      <span v-if="totalItems>0">{{totalItems}}</span>
    </v-btn>
  </template>
  <v-card>
    <v-card-title><h2>My Cart</h2><v-spacer/> <MyOrders v-if="currentUser"/></v-card-title>
    <v-card-text style="max-height: 200px">
      <UserCart/>

      <SelectPaymentMethod v-if="bill" @updatePaymentMethod="updatePaymentMethod"/>
    </v-card-text>
    <v-card-actions>
      <div style="width: 100%">
        <v-combobox
            solo
            class="custom-login-input custom-border-radius my-2"
            chips
            multiple
            hide-details
            :search-input.sync="voucher"
            v-model="vouchers"
            label="Vouchers"></v-combobox>
        <v-divider class="mb-5"></v-divider>
        <div class="d-flex justify-space-between">
          <div>Total</div>
          <div v-text="total"></div>
        </div>
        <v-btn color="accent" @click="generateBill" v-if="currentUser" :disabled="totalItems<=0 || userDataMissing.length>0" block class="mt-2 custom-border-radius">Checkout</v-btn>

        <RegisterFromCart :cart="cart" v-else :disabled="totalItems<=0"/>
        <div class="pa-5" v-if="userDataMissing && userDataMissing.length>0">
          <h4>Update your Profile to continue.</h4>
          <ul>
          <li v-for="message of userDataMissing" :key="message" v-text="message"></li>
          </ul>
        </div>
      </div>
    </v-card-actions>

  </v-card>
  <v-dialog content-class="fixed-dialog" max-width="500px" v-if="bill" v-model="showBill">
    <v-card>
      <v-card-text>
        <OrderBill @updatePaymentMethod="updatePaymentMethod" :bill="bill"/>
      </v-card-text>
      <v-card-actions>
        <v-btn block class="custom-border-radius" color="accent" @click="buy">Pay {{bill.grandTotal.amount}} {{bill.grandTotal.currency}}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <PayOrder @toggle="(v) => this.showPayment = v" v-if="orderSummary" :show="showPayment" :payment-link="orderSummary.paymentLink"/>
</v-dialog>
</template>

<script>
import UserCart from "@/components/cart/UserCart";
import MyOrders from "@/components/order/MyOrders";
import RegisterFromCart from "@/components/auth/RegisterFromCart";
import SelectPaymentMethod from "@/components/order/payment/SelectPaymentMethod";
import {getBill} from "@/services/ticket.service";
import Notification from "@/models/notification";
import OrderBill from "@/components/cart/OrderBill";
import PayOrder from "@/components/order/payment/PayOrder";
import {getAddressByUserId} from "@/services/address.service";

export default {
  name: "CartButtom",
  components: {PayOrder, OrderBill, SelectPaymentMethod, RegisterFromCart, MyOrders, UserCart},
  data(){
    return{
      voucher:null,
      address:null,
      show:false,
      bill:null,
      vouchers:[],
      showBill:false,
      paymentMethod:null,
      orderSummary:null,
      showPayment:false,
    }
  },
  props:{
    hideEmpty:Boolean,
    className:String,
    fab:Boolean
  },
  async created() {
    if(this.currentUser) {
      await this.$store.dispatch("cart/getCart");
      await this.getAddress();
    }
  },
  computed:{
    userDataMissing(){
      let missingData=[];
      if(!this.currentUser) {
        return null;
      }
      if(this.address){
        let address = this.address;
        if(!address.address) missingData.push("Address missing.");
        if(!address.postalCode) missingData.push("Postal Code missing.");
        if(!address.city) missingData.push("City missing.");
        if(!address.country) missingData.push("Country missing.")
      }else{
        missingData.push("Address missing.");
      }

      if(!this.currentUser.gender) missingData.push("Gender missing.");
      if(!this.currentUser.dateOfBirth) missingData.push("Date of Birth missing.");

      return missingData;
    },
    currentUser(){
      return this.$store.state.auth.user;
    },
    totalItems(){
      return this.cart.length;
    },
    total(){
      let Euro = new Intl.NumberFormat('de-DE', {
        style: 'currency',
        currency: 'EUR',
      });
      let response = 0;
      for(let item of this.cart){
        response+=item.amount*item.product.price;
      }
      return Euro.format(response);
    },
    cart(){
      return this.$store.state.cart.cart;
    }
  },
  methods:{
    async getAddress() {
      let address = await getAddressByUserId(this.currentUser.id);
      this.address = address;
    },
    updatePaymentMethod(value){
      //console.log(value);
      this.paymentMethod=value;
    },
    async generateBill() {
      this.vouchers.push(this.voucher);
      let data = await getBill(this.cart,this.vouchers);
      //console.log("Bill",data)
      if(data){
        this.bill=data;
        this.showBill=true;
      }
      else this.$store.dispatch("notificationsStore/add",new Notification("error", "Error validation your order. Please try again.", false));
    },
    async buy() {
      let order = await this.$store.dispatch("cart/buy", {paymentMethod: this.paymentMethod, products: this.cart});
      //console.log("Order",order);
      if(order && order.paymentLink){
        location.href=order.paymentLink;
        //this.orderSummary=order;
        //this.showPayment=true;
      }else{
       location.href="/payment/done?order_state=completed";
      }
    }
  }
}
</script>

<style scoped>
.button-pos{
  position: fixed;
  top:150px;
  right:25px;
}
</style>