import {getScoredUserlist} from "@/services/algorythm.service";
import store from "../store"
import ApiService from "@/services/api.service"

const initialState = {
    users:[],
    lobby:{
      users:[],
      page:0,
      totalPages:0,
      totalNumberOfUsers:0
    },
    recommendations:{
        users:[],
        sortBy:"Recommended",
        canSortBy:[
            "Recommended",
            "A to Z",
            "Z to A"
        ]
    },
}

export const network = {
    namespaced: true,
    state: initialState,
    actions: {
        async loadLobby({commit}) {
            let response = await ApiService.post("user/get/lobby/page/0?s=",{ids:[]});
            console.log("Response",response.data)
            console.log(response)
            if(response.status === 200){
                commit("initLobby",response.data)
            }
        },
        async search({commit},data){
            let page = 0;
            let search = data.search;
            let tagIds = data.tagIds;
            let response = await ApiService.post("user/get/lobby/page/"+page+"?s="+search,{ids:tagIds});
            if(response.status === 200){
                commit("initLobby",response.data);
            }
        },
        async loadMoreLobby({commit},data){
          let page = data.page;
          let search = data.search;
          let tagIds = data.ids;
            let response = await ApiService.post("user/get/lobby/page/"+page+"?s="+search,{ids:tagIds});
            if(response.status === 200){
                console.log("Respojnse",response)
                commit("addToLobby",response.data);
            }
        },
        setUsers({ commit }, users) {
            commit("setUsers",users)
        },
        setRecommendedUsers({ commit }, users) {
            commit("setRecommendedUsers",users)
        },
        sortRecommendedBy({commit},value){
            commit("sortRecommendedBy",value);
        }

    },

    mutations: {

        initLobby(state,data){
            if(store.state.auth.user) state.lobby.users = getScoredUserlist(data.users,store.state.auth.user);
            else state.lobby.users = data.users;
            state.lobby.totalNumberOfUsers= data.allUsers;
            state.lobby.page=1;
            state.lobby.totalPages= data.totalPages;
        },
        addToLobby(state,data){
            state.lobby.users = state.lobby.users.concat(data.users);
            state.lobby.totalNumberOfUsers= data.allUsers;
            state.lobby.page++;
            state.lobby.totalPages= data.totalPages;
        },
        setUsers(state, users){
            state.users=getScoredUserlist(users,store.state.auth.user);
        },
        setRecommendedUsers(state, users){
            state.recommendations.users=users;
        },
        sortRecommendedBy(state, value) {
            state.recommendations.sortBy=value;
        }


    }
};