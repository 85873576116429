<template>
<div >
  <p class="text-center font-weight-bold">Hi <span v-if="!isArtist" v-html="currentUser.firstName"></span><span v-else v-text="currentUser.name"></span><br/>
    Before you see our hottest events, tell us about yourself!
    <br/>
    <br/>
    Choose up to 3 tags that best describe you and your interests!


  </p>
  <div>
    <div style="height: 150px;overflow-y: auto">
      <v-chip-group
          class="centered-chips"
          multiple
          v-model="currentUser.tags"
          active-class="primary"
          column
          @change="updateUser(currentUser)"
          max="3"

      >
        <v-chip
            filter
            v-for="tag of tags"
            :key="tag.id"
            :value="tag.id"
        >
          {{ tag.name }}
        </v-chip>
      </v-chip-group>
     </div>
   <!-- <v-text-field v-model="newTagInput" style="max-width:200px;max-height: 35px" @keypress.enter="addNewTag(newTagInput)" @click:append="addNewTag(newTagInput)" solo rounded class="theme--light v-chip mx-auto d-flex align-center my-2" background-color="#e0e0e0" filled dense hide-details placeholder="Add your own..." append-icon="mdi-plus"></v-text-field>
-->
  </div>
  <div class="text-center ma-5 font-weight-bold" v-if="isArtist">You can change all you're information at any time in your profile</div>

</div>
</template>

<script>
import {updateUser} from "@/services/user.service";
import {addNewTag} from "@/services/tag.service";
import ApiService from "@/services/api.service"
export default {
  name: "ChooseTags",
  data(){
    return{
      tags:[],
      newTagInput:""
    }
  },
  computed:{
    isArtist(){
      return this.$store.state.app.welcome.isArtist;
    },
    currentUser(){
      return this.$store.state.auth.user;
    }
  },
  created() {
    this.getTags();
  },
  methods:{
    updateUser(user){
      updateUser(user);
    },
    addNewTag(name){
      let self=this;
      addNewTag(name).then(response=>{
        self.tags.push(response.data);
        self.currentUser.tags.push(response.data.id);
        self.newTagInput="";
        updateUser(self.currentUser);
        self.$store.dispatch("loading/success");
      })
    },
    getTags(){
      let self=this;
      ApiService.get("tags/get/all").then(response => {
        self.tags=response.data;
      });
    }
  }
}
</script>

<style scoped>

</style>