import ApiService from "@/services/api.service";
import {countNewMessages} from "@/services/socket.api";
import store from "../store";


export function loadContacts(){
    store.dispatch("loading/toggle",true);
    ApiService.post("user/getAllByIds/simple",{ids:store.state.auth.user.currentChatPartners}).then((users) => {
        console.log("Working");
        users.data.map((contact) =>
            countNewMessages(contact.id, store.state.auth.user.id).then((count) => {
                contact.newMessages = count;
                return contact;
            })
        );
        store.dispatch("chat/chatPartners",users.data);
        store.dispatch("loading/success");
        findLastMessages();
    });
}
export function findLastMessages(){

    for(let user of store.state.chat.currentChatPartners) {
        ApiService.post("user/messages/last", {senderId: store.state.auth.user.id, recipientId: user.id}).then(response => {
            console.log("LastMessage " + response.data);
            user.lastMessage = response.data;
        });

    }
}
export async function getUpdatedCurrentUser(){
    ApiService.get("user/get?id="+store.state.auth.user.id).then(response => {
        console.log("User",store.state.auth.user.id);
        console.log("DB User",response.data)
        ApiService.post("user/update",response.data).then(response2 => {
            let user =JSON.parse(localStorage.getItem("user"));
            let merged = {...user, ...response2.data};
            console.log("Merged User ",merged);
            store.dispatch("auth/updateLocalUser",merged);
            store.dispatch("loading/success");
            loadContacts();
        });
    });

}