<template>
<div v-if="ticket">
  <SingleTicketToBuy :ticket="ticket"/>
</div>
</template>

<script>
import SingleTicketToBuy from "@/components/ticket/SingleTicketToBuy";
import {getTicket} from "@/services/ticket.service";
export default {
  name: "SingleTicketID",
  components: {SingleTicketToBuy},
  props:{
    uid:String
  },
  data(){
    return{
      ticket:null
    }
  },
  async mounted() {
    if(this.uid) this.ticket = await getTicket(this.uid);
  }
}
</script>

<style scoped>

</style>