<template>
<div>
  <div class="image" ref="image" style="height:300px;">
    <v-btn v-if="anonym" style="position: fixed;z-index:99; left:25px;top:85px;" @click="$router.back()"><v-icon>mdi-arrow-left</v-icon> Back</v-btn>
  </div>
  <v-container style="max-width: 1100px" class="py-10" v-if="artistData">
    <v-chip  style="font-family: 'neueSingularH'" v-if="artistData.conference">Conference</v-chip>
    <v-chip  style="font-family: 'neueSingularH'" v-if="artistData.festival">Festival</v-chip>
    <h1 style="font-size: 4rem;">{{artistData.name}}</h1>
    <div>
      <h2 class="font-weight-regular genre-font" style="font-size: 3rem;">Genre: <span class="mx-2" v-for="genre of artistData.genre" :key="genre.id">{{genre.name}}</span></h2>
    </div>
    <div>
      <p class="my-5" style="font-size: 1.2rem;">{{artistData.bio}}</p>
    </div>
    <div>
      <h2 class="font-weight-regular" style="font-size: 2rem;">References: <span class="mx-2" v-for="reference of artistData.companies" :key="reference">{{reference}}</span></h2>
    </div>
    <div>

      <v-btn v-if="artistData.socials.spotify" :href="'https://open.spotify.com/artist/'+artistData.socials.spotify" target="_blank" icon color="primary">
        <v-icon>mdi-spotify</v-icon>
      </v-btn>
      <v-btn v-if="artistData.socials.soundcloud" :href="'https://soundcloud.com/'+artistData.socials.soundcloud" target="_blank" color="primary" icon>

        <v-icon>mdi-soundcloud</v-icon>
      </v-btn>
      <v-btn v-if="artistData.socials.instagram" :href="'https://www.instagram.com/'+artistData.socials.instagram" target="_blank" color="primary" icon>
        <v-icon>mdi-instagram</v-icon>
      </v-btn>
      <v-btn v-if="artistData.socials.youtube" :href="'https://www.youtube.com/'+artistData.socials.youtube" target="_blank" color="primary" icon>
        <v-icon>mdi-youtube</v-icon>
      </v-btn>
      <v-btn v-if="artistData.socials.linkedIn" :href="'https://www.linkedin.com/in/'+artistData.socials.linkedIn" target="_blank" color="primary" icon>
        <v-icon>mdi-linkedin</v-icon>
      </v-btn>
      <v-btn v-if="artistData.socials.twitter" :href="'https://twitter.com/'+artistData.socials.twitter" target="_blank" color="primary" icon>
        <v-icon>mdi-twitter</v-icon>
      </v-btn>
    </div>
  </v-container>

  <div style="background-color: #F8f8f8">
    <v-container style="max-width: 1100px"  class="py-15">
      <h2 class="mb-5" style="font-size: 2rem;">PARTICIPATES IN THE FOLLOWING EVENTS</h2>
      <EventItem v-for="event of events" :key="event.id" :event="event"/>
    </v-container>
  </div>
  <div >
    <v-container style="max-width: 1100px" class="py-15">
      <h2 class="mb-5" style="font-size: 2rem;">RELATED ARTISTS</h2>
      <div class="d-flex flex-wrap flex-md-nowrap justify-center justify-md-start" style="gap:30px">
        <ArtistItemPublic full v-for="artist of relatedArtists" :key="artist.id" :artist="artist"/>
      </div>

    </v-container>

  </div>
</div>
</template>

<script>
import {getArtistSite, getRelatedArtists} from "@/services/artist.service";
import {getEventsArtistParticipates} from "@/services/event.service";
import EventItem from "@/views/Event/EventItem";
import ArtistItemPublic from "@/components/artist/ArtistItemPublic";

export default {
  name: "ArtistSite",
  components: {ArtistItemPublic, EventItem},
  props:{
    id:String
  },
  data(){
    return{
      artistData:null,
      relatedArtists:[],
      events:[]
    }
  },
  computed: {
    anonym() {
      return this.$route.meta.hiddenHeaderAndFooter;
    },
  },
  async created() {
    await this.getArtistData();
    this.setBackground();
    this.getRelatedArtists();
    this.getEvents();
  },
  methods:{
    setBackground(){
      console.log("el",this.$refs.image)
      if(this.artistData.image)this.$refs.image.style.background="url('data:image/png;base64,"+this.artistData.image+"')"
    },
    async getArtistData() {
      this.artistData = await getArtistSite(this.id);
    },
    async getRelatedArtists(){
      this.relatedArtists = await getRelatedArtists(this.id);
    },
    async getEvents(){
      this.events = await getEventsArtistParticipates(this.id);
    }
  }
}
</script>

<style scoped>
.image{
  background: url('@/assets/Resize_Placeholder-Profile-819x1024.webp');
}
@media only screen and (max-width: 600px) {
  .genre-font {
    font-size: 1.3rem!important;
  }
}
</style>