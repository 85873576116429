<template>
<div>
  <p class="text-center font-weight-bold">Tell us more about what<br/> you're doing</p>
  <Professions />
</div>
</template>

<script>
import {updateUser} from "@/services/user.service";
import Professions from "@/views/Welcome/Professions";

export default {
  name: "WorkInformation",
  components: {Professions},
  computed:{
    currentUser(){
      return this.$store.state.auth.user;
    }
  },
  methods: {
    updateUser(user) {
      updateUser(user);
    },
  }
}
</script>

