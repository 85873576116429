<template>
  <div>
    <v-row style="height:100vh">
      <v-col style="min-height: 100vh;position: relative"   class="col-12 col-md-6 align-content-center justify-center">
        <v-img :src="require('@/assets/Logo_b_transparent.png')" style="opacity: 0.02;position:absolute;top:0;right:0;transform: translateX(50%) translateY(25%)" width="800" height="800"/>
        <div class="mx-auto px-5" style="max-width: 400px">
          <v-img :src="require('@/assets/logo2023_b-150x54.png.webp')" height="50" width="150"></v-img>
          <h1>Create your account</h1>
          <h4 class="mb-5 font-weight-regular">Welcome to the Berlin Dance Music Event Networking Tool.</h4>
          <div class="mt-5 mb-2 d-flex align-center" style="gap:20px">
            <v-divider></v-divider><span class="text--disabled">Register</span><v-divider/>
          </div>
          <v-form ref="form" class="mx-5" v-model="valid">

            <v-text-field
                class="custom-login-input"
                autofocus
                solo
                rounded
                label="Enter First Name"
                v-model="user.firstName"
                required
            />
            <v-text-field

                class="custom-login-input"
                autofocus
                solo
                rounded
                label="Enter Last Name"
                v-model="user.lastName"
                required
            />
            <v-text-field

                class="custom-login-input"
                autofocus
                solo
                rounded
                label="Enter Artist- /Nickname"
                v-model="user.name"
                :counter="20"
                :rules="usernameRules"
                required
            />
            <v-text-field

                class="custom-login-input"
                solo
                type="email"
                rounded
                label="Enter your email address"
                v-model="user.email"
                :rules="emailRules"
                required
            />

            <v-text-field

                class="custom-login-input"
                solo
                rounded
                autocomplete="current-password"
                :value="userPassword"
                label="Enter password"
                :hint="$t('registration.passwordCheck')"
                :append-icon="value ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="() => (value = !value)"
                :type="value ? 'password' : 'text'"
                :rules="[rules.password,rules.required]"
                @input="_=>userPassword=_"
            ></v-text-field>
            <v-text-field

                class="custom-login-input"
                solo
                autocomplete="current-password"
                :append-icon="value ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="() => (value = !value)"
                :type="value ? 'password' : 'text'"
                rounded
                label="Confirm password"
                v-model="confirmPassword"
                :rules="[[v => !!v || 'Password is required'], (this.userPassword === this.confirmPassword) || 'Passwords must match']"
                required
            />
            <v-row>
              <v-col class="shrink d-flex align-center">
                <v-checkbox class="ma-0 pa-0" :rules="[v => !!v]" hide-details>

                </v-checkbox>
              </v-col>
              <v-col class="d-flex align-center">
          <span>
            <span>I accept the</span> <a class="mx-1" href="https://berlin-dance-music-event.com/agb/"><span> terms and conditions </span></a> and <a class="mx-1" href="https://berlin-dance-music-event.com/privacy-policy/">data protection
          </a>
            </span>
              </v-col>
            </v-row>

            <div class="py-5">
              <v-btn block color="success" :disabled="!valid" class="mr-2" @click="handleRegister">{{ $t('registration.btnOk') }}</v-btn>
              <div class="mt-5 d-flex justify-center align-center">
                <span>Already have an account?</span> <v-btn plain @click="$router.push('/login')">Login</v-btn>
              </div>
            </div>
          </v-form>
          <v-alert
              v-if="message"
              class="alert"
              :class="successful ? 'alert-success' : 'alert-danger'"
          >{{message}}</v-alert>
          </div>

      </v-col>
      <v-col style="height: 100vh"  class="primary col-12 col-md-6">
        <v-carousel
            dark
            height="80vh"

            hide-delimiter-background
            show-arrows-on-hover
        >
          <v-carousel-item
          >
            <div style="position: absolute;top:50%;left:50%;transform: translateY(-50%) translateX(-50%)">
              <v-img class="mb-5 mx-auto" width="400px" height="400px" src="https://images.unsplash.com/photo-1713199716594-033b0991c2c6?q=80&w=2128&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"></v-img>
              <h3 class="text-center" style="color:white;">Build your network.</h3>
              <h5 class="text-center" style="color:white;">Connect with people all over the world.</h5>
            </div>
          </v-carousel-item>
          <v-carousel-item
          >
            <div style="position: absolute;top:50%;left:50%;transform: translateY(-50%) translateX(-50%)">
              <v-img class="mb-5 mx-auto" width="400px" height="400px" src="https://images.unsplash.com/photo-1713199716594-033b0991c2c6?q=80&w=2128&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"></v-img>
              <h3 class="text-center" style="color:white;">Get the full experience.</h3>
              <h5 class="text-center" style="color:white;">Create your own timetable. Chat with others.</h5>
            </div>
          </v-carousel-item>
        </v-carousel>
      </v-col>
    </v-row>


    </div>
</template>

<script>
import User from '../models/userRegister';
export default {
  name: 'RegisterView',
  data() {
    return {
      usernameRules:[
        v => !!v || this.$t('registration.nameRequired'),
        v => (v && v.length <= 20) || this.$t('registration.nameLength'),
      ],
      emailRules: [
        v => !!v || this.$t('registration.emailRequired'),
        v => /.+@.+\..+/.test(v) || this.$t('registration.emailValid'),
      ],

      user: new User('', '', '','','',''),
      url:null,
      confirmPassword:"",
      submitted: false,
      successful: false,
      message: '',
      userPassword: "",
      valid: true,
      value: true,
      rules: {
        required: value => !!value || this.$t('registration.passwordRequired'),
        password: value => {
          const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/;
          return (
              pattern.test(value) ||
              this.$t('registration.passwordCheck')
          );
        }
      }
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    }
  },
  mounted() {
    if (this.loggedIn) {
      this.$router.push('/profile');
    }
  },
  methods: {
    Preview_image(e){
      let files = e.target.files || e.dataTransfer.files;
      if(!files.length) return;
      this.user.image=files[0]
      this.url=URL.createObjectURL(this.user.image)
    },
    handleCancel(){
      this.$refs.form.reset()
    },

    handleRegister() {
      this.message = '';
      this.submitted = true;
      if (this.valid) {
        console.log(this.user);
        this.user.password=this.userPassword;
        this.$store.dispatch('auth/register', this.user).then(
            () => {
              this.message = "Erfolgreich Registriert";
              //ImageUpload(this.user.image,data);
              this.successful = true;
              this.$router.push("/welcome");

            },
            error => {
              this.message =
                  (error.response && error.response.data) ||
                  error.message ||
                  error.toString();
              this.successful = false;
            }
        );
      }
      //this.$router.push('/successful-registration?email='+this.user.email);
    }
  }


};
</script>

<style scoped>

</style>