<template>
<div style="height:100%">

  <v-layout fill-height>
    <v-flex xs12>
      <router-view></router-view>
    </v-flex>
  </v-layout>

</div>
</template>

<script>
import ApiService from "@/services/api.service";

export default {
  name: "ChatWrapper",
  components: {},
  data(){
    return{
      contacts:[],
      currentChatPartner:{
        id:2
      },
      activeContact: {
        id:2
      },
      messages:[],
      newMessage:""
    }
  },
  created() {
  },
  methods:{
    sendTest(){
      ApiService.post("notification/token",{
        "topic": "common",
        "title": "Congrats",
        "message": "Du hast den Chat geöffnet",
        "token":this.$store.state.notificationsStore.firebase,
        "payloadMessageId": "123",
        "payloadData": "Hello. This is payload content."
      })
    }
  },
  computed:{
    currentUser(){
      return this.$store.state.auth.user;
    }
  }
}
</script>

