<template>
<div>
  <div style="width:100%" class=" d-flex justify-center text-center font-weight-bold"><p style="width:80%">Take a photo to show everyone who u are</p></div>
  <div style="height:300px" class="mx-auto overflow-hidden">
  <WebcamVideo @upload="compressWebcamImage"/>
  </div>
  <div class="d-flex justify-center">
  <v-btn :loading="loading" plain text class="mx-auto">
    <v-icon class="mr-1">mdi-image-multiple</v-icon>
    Use Camera Roll

    <input type="file"
           class="file-input"
           style="opacity:0;width:100%;height:100%;position: absolute;top:0;left:0"
           @change="setImage"
           accept="image/png, image/jpeg,image/bmp"
    />
  </v-btn>
  </div>
  <v-dialog content-class="overflow-hidden" fullscreen max-width="100%" style="max-height: 100%;" v-model="hasImage">
    <v-card>
      <v-card-actions>
        <v-btn text @click="hasImage=false">Cancel</v-btn>
        <v-spacer></v-spacer>
        <v-btn text @click="cropCameraRoll"  :loading="loading">Upload</v-btn>
      </v-card-actions>

      <cropper
          ref="cropper"
          class="upload-example-cropper"
          :src="img"
          style="width:100%; height:100%"
          :stencil-props="{
		handlers: {},
		movable: false,
		scalable: false,
	}"
          :stencil-size="{
		width: 280,
		height: 280
	}"
          image-restriction="stencil"
          :stencil-component="$options.components.Stencil"


      />

    </v-card>
  </v-dialog>
</div>
</template>

<script>
import Compressor from "compressorjs";
import UploadFile from "@/services/upload-file";
import WebcamVideo from "@/views/Welcome/WebcamVideo";
import {updateUser} from "@/services/user.service";
import {crop} from "@/services/crop.service";

export default {
  name: "WelcomeChangeImage",
  components: {WebcamVideo},
  data(){
    return{
      img:null,
      hasImage:false,
    }
  },
  computed:{
    loading(){
      return this.$store.state.loading.loadingState
    },
    currentUser(){
      return this.$store.state.auth.user;
    }
  },
    methods:{
      cropCameraRoll() {
        this.$store.dispatch("loading/toggle",true);
        this.$store.dispatch("loading/toggle",true);
        const result = this.$refs.cropper.getResult();
        this.compressImage(this.dataURItoBlob(result.canvas.toDataURL()));

      },
      setImage: function (e) {
        this.hasImage = true
        let files = e.target.files || e.dataTransfer.files;
        if(!files.length) return;
        else this.img = URL.createObjectURL(files[0]);

      },
      dataURItoBlob(dataURI) {
// convert base64/URLEncoded data component to raw binary data held in a string
        var byteString;
        if (dataURI.split(',')[0].indexOf('base64') >= 0)
          byteString = atob(dataURI.split(',')[1]);
        else
          byteString = unescape(dataURI.split(',')[1]);
// separate out the mime component
        var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
// write the bytes of the string to a typed array
        var ia = new Uint8Array(byteString.length);
        for (var i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ia], {type:mimeString});
      },
      compressWebcamImage(imageUrl){
        this.loading=true;
        this.$store.dispatch("loading/toggle",true);
        crop(imageUrl,1).then(canvas => {
          this.compressImage(this.dataURItoBlob(canvas.toDataURL()));
        });
        //this.compressImage(this.dataURItoBlob(imageUrl))
      },
      compressImage(file){
        let self=this;
        new Compressor(file, {
          quality: 0.6,
          width:720,

          // The compression process is asynchronous,
          // which means you have to access the `result` in the `success` hook function.
          success(result) {
            self.changeImage(result)
          },
          error(err) {
            console.log(err.message);
            self.setSnack({
              message: "Fehler beim Komprimieren Ihrer Datei. Bitte verwenden Sie ein anderes Bild",
              color: "error"
            })
          },
        });
      },
      changeImage(image){
        let self=this;

        if(image.size>1000000){
          self.$store.dispatch("notificationsStore/add",new Notification("Datei zu groß "+Math.round(image.size/1000000)+" /1MB. Versuchen Sie das Bild zu verkleinern","error"));
          self.$store.dispatch("loading/error");
          self.loading=false;
        }
        else {
          UploadFile(image).then(response => {
            self.currentUser.profilePic=response.data;

            updateUser(self.currentUser)

            self.hasImage=false;

            setTimeout(()=>{this.$router.push("/lobby");
              self.loading=false;
            },4000);

            self.$store.dispatch("loading/success");
          })
        }

      },
  }
}
</script>

<style scoped>

</style>