<template>
<v-chip class="pa-5" color="accent" @click="addToCartId">
  <v-icon>mdi-cart</v-icon> Add ticket to cart
</v-chip>
</template>

<script>
import {getTicket} from "@/services/ticket.service";

export default {
  name: "AddToCartButton",
  props:{
    ticketId:String
  },
  methods:{
    async addToCartId() {
      let ticket = await getTicket(this.ticketId);
      console.log("ticket",ticket);
      this.addToCart(ticket);
    },
    addToCart(ticket) {
      this.loading=true;
      if(this.currentUser)this.$store.dispatch("cart/addToCart", ticket);
      else{
        if(this.isAlreadyInCart>0) this.$store.dispatch("cart/addOneAnonym",{amount:this.isAlreadyInCart, product:ticket});
        else this.$store.dispatch("cart/addToCartAnonym",{amount:0, product:ticket});
      }
      this.loading=false;
    },
  },
  computed:{
    isAlreadyInCart(){
      let self=this;
      if(this.ticket && this.cart.findIndex(x => x.product.uid === self.ticket.uid) !=-1) return this.cart[this.cart.findIndex(x => x.product.uid === self.ticket.uid)]?.amount;
      else return -1;
    },
    cart(){
      return this.$store.state.cart.cart;
    },
    currentUser(){
      return this.$store.state.auth.user;
    }
  },
}
</script>

<style scoped>

</style>