<template>
<v-dialog max-width="500px" overlay-opacity="0.9npm" v-model="privacyNotAccepted" persistent>
  <v-card>
    <v-card-title>We updated our privacy policies.</v-card-title>
    <v-card-text>
      To use our tool you have to <span>accept the</span> <a target="_blank" class="mx-1" href="https://berlin-dance-music-event.com/agb/"><span> terms and conditions </span></a> and <a target="_blank" class="mx-1" href="https://berlin-dance-music-event.com/privacy-policy/">data protection.</a>

    </v-card-text>
    <v-card-actions>
      <v-btn plain @click="logout">Logout</v-btn><v-btn color="success" @click="accept">Accept</v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>
</template>

<script>

export default {
  name: "AcceptTermsAndConditionsPopup",
  computed: {
    privacyAccepted() {
      if (!this.$store.state.auth.user) {
        return true;
      } else if (this.$store.state.auth.user?.acceptedPrivacyPolicy) {

      return true;
    }
    else return false;
  },
    privacyNotAccepted(){
      return !this.privacyAccepted
    }
  },
  methods:{
    accept() {
      this.$store.dispatch("auth/acceptPrivacy");
    },
    logout(){
      this.$store.dispatch("auth/logout");
      this.$router.push("/login");
    }
  }
}
</script>

<style scoped>

</style>