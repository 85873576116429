<template>
  <div class="ma-xs-0">
    <v-app-bar color="" dark app style="z-index:30;background: var(--v-primary-base);" class="pb-5" flat width="100%">
      <div style="width:100vw;height:100%;background: linear-gradient(90deg,rgba(0,0,0,1)  40%, rgba(131,52,146,1) 70%);position:absolute;top:0;left:0; z-index:0"></div>


<div style="max-width: 1200px; width:100%;" class="mx-auto d-flex justify-space-between align-center">
      <v-btn icon @click="$router.back()"><v-icon>mdi-arrow-left</v-icon></v-btn>
 <div style="height:50px;width:50px">
   <v-img v-if="!$vuetify.theme.dark" height="45px" width="45px" :src="require('../../assets/Logo_w_transparent.png')"></v-img>
   <v-img v-else height="45px" width="45px" :src="require('../../assets/Logo_w_transparent.png')"></v-img>
 </div>
  <v-spacer></v-spacer>
 <!-- <div style="font-size:1.8rem;position: absolute;bottom:0em;left:50%;transform:translateX(-50%);" v-if="$route.name=='activeChat'">

    <span v-if="$store.state.chat.activeContact" v-html="$store.state.chat.activeContact.firstName"></span>
    <span v-if="$store.state.chat.activeContact" class="ml-1" v-html="$store.state.chat.activeContact.lastName" ></span>
  </div>
  <v-app-bar-title style="color: #FFFFFF24;
    font-family: 'Neue Singular H', Sans-serif;
    font-weight: 600;
    letter-spacing: 5px;
    -webkit-text-stroke-width: 1px;
    stroke-width: 1px;
    -webkit-text-stroke-color: #FFFFFF;
    stroke: #FFFFFF;font-size:1.5rem;position: absolute;bottom:5px;left:50%;transform:translateX(-50%);"  class="py-1 text-uppercase" v-else></v-app-bar-title>
-->
  <div class="hidden-sm-and-down">
  <v-btn class="text-capitalize" :disabled="$route.name==='Feed'" text href="/feed">Feed</v-btn>
  <v-btn class="text-capitalize" :disabled="$route.name==='Lobby'" text href="/lobby">Lobby</v-btn>
  <v-btn class="text-capitalize" :disabled="$route.name==='Events'" text href="/events">Program</v-btn>
  <v-btn class="text-capitalize" :disabled="$route.name==='My Timetable'" text href="/favourites">My timetable</v-btn>
  </div>
  <v-spacer></v-spacer>


  <!--<div>
    <v-slide-y-transition>
    <v-tooltip v-if="!$vuetify.theme.dark" bottom>
      <template v-slot:activator="{ on }">
        <v-btn v-on="on" class="elevation-0" color="transparent" small fab @click="darkMode">
          <v-icon class="mr-1">mdi-moon-waxing-crescent</v-icon>
        </v-btn>
      </template>
      <span>Dark Mode On</span>
    </v-tooltip>

    <v-tooltip v-else bottom>
      <template v-slot:activator="{ on }">
        <v-btn small v-on="on" class="elevation-0" color="transparent" icon @click="darkMode">
          <v-icon class="mr-1" color="yellow">mdi-white-balance-sunny</v-icon>
        </v-btn>
      </template>
      <span>Dark Mode Off</span>
    </v-tooltip>
    </v-slide-y-transition>
  </div>-->
  <CartButtom/>
  <v-btn icon :disabled="$route.name==='Chats'" @click="$router.push('/chat')">
    <v-badge
        v-if="unreadMessages>0"
        :content="unreadMessages"
        overlap
    >
      <v-icon>mdi-message</v-icon>
    </v-badge>
    <v-icon v-else>mdi-message</v-icon>
  </v-btn>
  <div style="z-index:10">
  <v-btn  :disabled="$route.name==='Profile'" @click.prevent="$router.push('/profile')" v-if="!currentUser" icon><v-icon>mdi-account</v-icon></v-btn>
  <v-btn  :disabled="$route.name==='Profile'" @click.prevent="$router.push('/profile')" v-else icon><v-avatar size="35px"><v-img :src="currentUser.profilePic?'data:image/png;base64,'+currentUser.profilePic.file :require('../../assets/Placeholder-Profile-819x1024.webp')"
  ></v-img></v-avatar></v-btn>
  </div>
  <v-app-bar-nav-icon @click="drawer = true"></v-app-bar-nav-icon>
</div>
</v-app-bar>
    <v-progress-linear style="position:fixed;z-index:9999;left:0;top:0;" v-if="$store.state.loading.loadingState || $store.state.loading.success.state"
                       :indeterminate="!$store.state.loading.success.state"
                       class="animate__animated"      :class="{'animate__fadeOutUp':$store.state.loading.success.animateOut,'bg-success':$store.state.loading.success.state=='success','bg-error':$store.state.loading.success.state=='error'}"
                       color="accent"
    ></v-progress-linear>
    <v-dialog
        v-model="drawer"
        app
        fullscreen
    >
      <OffCanvasMenu @close="() => this.drawer=false"/>
    </v-dialog>
    <!--<v-navigation-drawer
        v-model="drawer"
        app
        right
        temporary
        style="z-index:500"
    >
      <v-list
          nav
          dense
      >
        <v-list-item-group
            active-class="primary--text text--accent-4"
        >
          <v-list-item v-for="navItem in $store.state.app.navItems" :key="navItem.title" @click="gotTo(navItem.target)">
            <v-list-item-title style="font-size: 1rem;" class="text-uppercase font-weight-bold text-center">{{navItem.title}}</v-list-item-title>
          </v-list-item>
          <v-list-item link href="https://berlin-dance-music-event.com" target="_blank">
            <v-list-item-title class="text-uppercase text-right">
              BDME Website
            </v-list-item-title>
          </v-list-item>

          <v-list-item v-if="canAccessManagement" link href="/management">
            <v-list-item-title class="text-uppercase text-right"><v-icon>mdi-lock-open</v-icon>Conference Management</v-list-item-title>
          </v-list-item>

-->
    <!--
          <v-list-item @click="$router.push('/welcome')">
            <v-list-item-title class="text-uppercase text-right">DEV - Welcome</v-list-item-title>
          </v-list-item><v-list-item @click="$router.push('/my/network')">
            <v-list-item-title class="text-uppercase text-right">DEV - Network</v-list-item-title>
          </v-list-item>
          <v-list-item @click="$router.push('/game')">
          <v-list-item-title class="text-uppercase text-right">DEV - Swipe</v-list-item-title>
        </v-list-item>
-->
    <!--
        </v-list-item-group>
      </v-list>


        <v-btn text plain style="position: absolute;bottom:5px;right:5px" @click="handleLogout" class="text-right"><v-icon small >mdi-logout</v-icon> Log out</v-btn>

    </v-navigation-drawer>-->
  </div>
</template>

<script>
import OffCanvasMenu from "@/views/Navigation/OffCanvasMenu";
import CartButtom from "@/components/cart/CartButtom";
export default {
  name: "TopbarSide",
  components: {CartButtom, OffCanvasMenu},
  data(){
    return{
      drawer:false
    }
  },
  computed:{
    currentUser(){
      return this.$store.state.auth.user;
    },
    canAccessManagement(){
      return this.currentUser.roles.includes("ROLE_ADMIN") || this.currentUser.roles.includes("ROLE_PROMOTER") || this.currentUser.roles.includes("ROLE_CONF_MANAGER");
    },
    chatPartners(){
      return this.$store.state.chat.currentChatPartners
    },
    unreadMessages(){
      const sum = this.chatPartners.reduce((accumulator, object) => {
        return accumulator + object.newMessages;
      }, 0);
      return sum;
    }
  },
  methods:{
    darkMode() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
    },
    handleLogout:function(){
      this.$store.dispatch('auth/logout')
      this.$router.push("/")
    },
    gotTo(target){
      this.$router.push("/"+target);
    },
  }
}
</script>

<style scoped>

</style>