<template>
  <v-list color="transparent" min-height="100%" style="position: relative">


    <div v-for="user of chatPartners" :key="user.id">
    <v-list-item link
                 @click="openChat(user.id)">


      <v-list-item-avatar width="45px" height="45px" rounded :class="{'unread':user.newMessages>0}">

        <v-img :src="user.image?'data:image/png;base64,'+user.image :require('../../assets/Placeholder-Profile-819x1024.webp')"
        ></v-img>

      </v-list-item-avatar>


        <v-list-item-content>
          <v-badge
              v-if="user.newMessages>0"
              bordered
              color="success"
              :content="user.newMessages"
              overlap
              offset-y="15px"
              offset-x="20px"
          >
        <v-list-item-title class="d-flex justify-space-between" ><span class="font-weight-bold" v-html="user.name"></span>
        </v-list-item-title>
          </v-badge>
          <v-list-item-title v-else class="d-flex justify-space-between" ><span class="font-weight-bold" v-html="user.name"></span>
          </v-list-item-title>
            <v-list-item-subtitle class="d-flex justify-space-between" v-if="user.lastMessage && user.lastMessage.content"><small ><span v-html="user.lastMessage.content.substring(0,10)"></span><span v-if="user.lastMessage.content.length>9">...</span></small><small><span v-html="timestamp(user.lastMessage.timestamp)"></span></small>

         </v-list-item-subtitle>
          <v-list-item-subtitle v-else>
            -
          </v-list-item-subtitle>
      </v-list-item-content>

    </v-list-item>
    <v-divider class="mx-5"></v-divider>
    </div>
    <AddNewChat/>
    <div class="lobby-loader">
      <v-skeleton-loader v-if="loadingChatPartners>0" :type="'list-item-avatar-two-line@'+loadingChatPartners"></v-skeleton-loader>
    </div>
  </v-list>
</template>
<script>
import AddNewChat from "@/views/Chat/AddNewChat";
import {findLastMessages, loadContacts} from "@/services/chat.service";
export default {
  name: 'ChatPartners',
  components: {AddNewChat},
  props: {

  },
  data(){
    return{
    }
  },
  computed:{
    chatPartners(){
      return this.$store.state.chat.currentChatPartners
    },
  currentUser(){
    return this.$store.state.auth.user;
  },
    loadingChatPartners(){
      return this.currentUser.currentChatPartners.length-this.chatPartners.length;
    }
  },
  watch:{
    '$store.state.chat.messages'(){
      findLastMessages();
    }
  },
  beforeRouteEnter (to, from, next) {

    next(vm => {

      // access to component public instance via `vm`
      vm.getUpdatedCurrentUser();
    })
  },
  created(){
    this.getUpdatedCurrentUser();
  },
  mounted() {
    if(this.currentUser.currentChatPartners && this.currentUser.currentChatPartners.length>0) loadContacts();
  },methods:{
    timestamp(date){
      let moment = require('moment');
      return moment(date).format('LT');
    },
    openChat(id){
      this.$router.push('/chat/open/'+id);

    },
    getUpdatedCurrentUser(){

    },
  }
}
</script>
<style scoped>

</style>
