<template>
  <v-container class="" style="min-height: 90vh;">
    <h1 class="mt-5">Conference Venues</h1>
    <div style="gap: 20px;" class="d-flex align-start align-content-start my-5 flex-wrap justify-center justify-md-start">

      <VenueItem v-for="venue of conference" :venue="venue" :key="venue.name"/>
    </div>
    <h1 class="mt-5">Festival Venues</h1>
    <div style="gap: 20px;" class="d-flex justify-start align-start align-content-start my-5 flex-wrap justify-center justify-md-start">

      <VenueItem v-for="venue of festival" :venue="venue" :key="venue.name"/>
    </div>

  </v-container>
</template>

<script>
import VenueItem from "@/components/venue/VenueItem";
import {getAllVenues} from "@/services/venue.service";
export default {
  name: "AllVenues",
  components: {VenueItem},
  data(){
    return{
      venues:[]
    }
  },
  methods:{
    async getAllVenues() {
      this.venues = await getAllVenues();
    }
  },
  computed:{
    conference(){
      return this.venues.filter(x => x.conference);
    },
    festival(){
      return this.venues.filter(x => !x.conference);
    }
  },
  created() {
    this.getAllVenues();
  }
}
</script>

<style scoped>

</style>