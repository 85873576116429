<template>
<v-container>
  <h2 class="mt-10 mb-5">Press Kits</h2>
  <v-text-field label="Search for Users..." solo class="custom-border-radius" append-icon="mdi-search" v-model="search"/>
<PressUserList :press-kit-users="filteredUsers"/>
</v-container>
</template>

<script>
import PressUserList from "@/components/press/PressUserList";
import {getAllPressKits} from "@/services/press.service";
export default {
  name: "PressUsersView",
  components: {PressUserList},
  data(){
    return{
      users:[],
      search:''
    }
  },
  computed:{
    filteredUsers(){
      if(this.users.length<=0) return [];
      else return this.users.filter(x => x.user.name.toLowerCase().includes(this.search.toLowerCase()))
    }
  },
  created() {
    this.getUsers();
  },
  methods:{
    async getUsers() {
      this.users = await getAllPressKits();
    }
  }
}
</script>

<style scoped>

</style>