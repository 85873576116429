<template>
  <div style="width:100%;height:100%;">
  <div style="width:auto;height:100%;position:relative" class="mx-auto custom-border-radius">
    <v-img class="custom-border-radius" style="position:absolute;top:0;left:0;max-width:100%;max-height:100%;" width="100%" height="100%" :src="image ? 'data:image/png;base64,'+image :require('@/assets/Placeholder-Profile-819x1024.webp')">
    </v-img>
    <v-chip style="position:absolute;left:5px;bottom:5px;">
      Click to edit
      <v-icon class="ml-1">mdi-camera</v-icon>
    </v-chip>
      <input type="file" style="position:absolute;top:0;left:0;width:100%;height:100%;z-index:1;opacity: 0;" placeholder="File" @change="setImage"/>
  </div>
    <v-dialog content-class="overflow-hidden" max-width="100%" style="max-height: 100%;" v-model="hasImage">
      <v-card>
        <v-card-actions>
          <v-btn text @click="img=null">Cancel</v-btn>
          <v-spacer></v-spacer>
          <v-btn text @click="crop"  :loading="loading">Save</v-btn>
        </v-card-actions>

        <cropper
            ref="cropper"
            class="upload-example-cropper"
            :src="img"
            style="width:100%; height:100%;max-height: 90vh"
            :stencil-props="{
		handlers: {},
		movable: false,
		scalable: false,
	}"
            :stencil-size="{
		width: 560,
		height: 560
	}"
            image-restriction="stencil"
            :stencil-component="$options.components.Stencil"
            defaultBoundaries="fit"

        />
        <v-card-actions class="d-flex justify-space-between">
          <div>
            <v-btn icon @click="zoom(2)"><v-icon>mdi-magnify-plus</v-icon></v-btn>
            <v-btn icon @click="zoom(0.5)"><v-icon>mdi-magnify-minus</v-icon></v-btn>
          </div>
          <div>
            <v-btn icon @click="rotate(90)"><v-icon>mdi-rotate-right</v-icon></v-btn>
            <v-btn icon @click="rotate(-90)"><v-icon>mdi-rotate-left</v-icon></v-btn>
          </div>
          <div>
            <v-btn icon @click="flip(true,false)"><v-icon>mdi-flip-horizontal</v-icon></v-btn>
            <v-btn icon @click="flip(false,true)"><v-icon>mdi-flip-vertical</v-icon></v-btn>
          </div>
        </v-card-actions>
        <!--<v-chip style="" class="mx-auto my-2">Use your Fingers or Mousewheel to zoom <v-icon>mdi-gesture-pinch</v-icon></v-chip>
      -->
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {compressImageCustom} from "@/services/image.service";
export default {
  name: "ImagePickerWithCropping",

  props:{
    image:String
  },
  data(){
    return{
      img:null,
      loading:false
    }
  },
  computed:{
    hasImage:{
      get(){
        return this.img!=null
      },set(value){
        if(!value) this.img=null;
      }
    }
  },
  methods:{
    zoom(factor) {
      this.$refs.cropper.zoom(factor);
    },
    move(direction) {
      if (direction === 'left') {
        this.$refs.cropper.move(-this.size.width / 4);
      } else if (direction === 'right') {
        this.$refs.cropper.move(this.size.width / 4);
      } else if (direction === 'top') {
        this.$refs.cropper.move(0, -this.size.height / 4);
      } else if (direction === 'bottom') {
        this.$refs.cropper.move(0, this.size.height / 4);
      }
    },
    flip(x,y) {
      this.$refs.cropper.flip(x,y);
    },
    rotate(angle) {
      this.$refs.cropper.rotate(angle);
    },
    setImage: function (e) {
      //this.hasImage = true
      let files = e.target.files || e.dataTransfer.files;
      if(!files.length) return;
      else this.img = URL.createObjectURL(files[0]);

    },
    crop() {
      this.loading=true;
      const result = this.$refs.cropper.getResult();
      this.compressImage(this.dataURItoBlob(result.canvas.toDataURL()));

    },
    dataURItoBlob(dataURI) {
// convert base64/URLEncoded data component to raw binary data held in a string
      var byteString;
      if (dataURI.split(',')[0].indexOf('base64') >= 0)
        byteString = atob(dataURI.split(',')[1]);
      else
        byteString = unescape(dataURI.split(',')[1]);
// separate out the mime component
      var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
// write the bytes of the string to a typed array
      var ia = new Uint8Array(byteString.length);
      for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      return new Blob([ia], {type:mimeString});
    },
    compressImage(file){
      let compressedFile = compressImageCustom(file,0.1,300).file;
      const reader = new FileReader();
      reader.onload = (event) => {
        this.loading=false;
        this.$emit("updateImage", event.target.result.split(',')[1]);
        this.img=null;
      }
      reader.readAsDataURL(compressedFile);
    },
  }
}
</script>

<style scoped>

</style>