import checkWord from "@/services/checkWord";
import ApiService from "@/services/api.service";
import Notification from "@/models/notification";

export function addNewTag(name){
    if(!checkWord(name)){
        this.$store.dispatch("notificationsStore/add",new Notification("error","Dieses Wort ist nicht erlaubt!",false))
        return null;
    }
   return ApiService.post("tags/save",{id:null,name:name})
}