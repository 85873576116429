<template>
<div class="my-5 el custom-border-radius">
  <v-list-item >
    <v-list-item-avatar>
        <UserAvatar :user="content.autor"/>
    </v-list-item-avatar>
    <v-list-item-content>
    <v-list-item-title  ><a style="text-decoration: none;font-size: 1.1rem" :href="'/user/'+content.autor.id" v-text="content.autor.name"></a></v-list-item-title>
    <v-list-item-subtitle></v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-action>
      <v-menu>
        <template #activator="{attrs,on}">
          <v-btn icon v-bind="attrs" v-on="on"><v-icon>mdi-dots-vertical</v-icon></v-btn>
        </template>
        <v-list>
          <v-list-item @click="deleteItem(content.id)">
            <v-list-item-icon>
              <v-icon>mdi-delete</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Delete</v-list-item-title>

          </v-list-item>
        </v-list>
      </v-menu>

    </v-list-item-action>
  </v-list-item>
  <v-img v-if="content.file" :src="'data:image/png;base64,'+content.file" width="100%"></v-img>
  <div class="d-flex px-2 py-1 justify-start">
    <v-btn v-if="!currentUserLikes" @click="like" icon><v-icon>mdi-heart-outline</v-icon></v-btn>
    <v-btn v-else icon color="red" @click="dislike"><v-icon>mdi-heart</v-icon></v-btn>
    <v-bottom-sheet v-model="showComments" scrollable fullscreen>
    <template v-slot:activator="{attrs,on}">
      <v-btn v-bind="attrs" v-on="on" icon><v-icon>mdi-message-outline</v-icon></v-btn>

    </template>
      <v-card>
        <v-btn plain @click="showComments=false"><div style="height:4px;width:100px;background-color:black;border-radius: 3px"></div></v-btn>
        <v-card-text>
          <v-list>
            <v-list-item>
              <v-list-item-avatar>
                <v-img :src="content.autor.profilePic?'data:image/png;base64,'+content.autor.profilePic.file : require('@/assets/Placeholder-Profile-819x1024.webp')">

                </v-img>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title style="font-size: 0.7rem;">
                  <span v-text="content.autor.name"></span><span  class="font-weight-bold ml-2">{{firstCommentDate}}</span>
                </v-list-item-title>
                <v-list-item-subtitle v-text="content.text">

                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <CommentsList @addComment="addComment" @deleteComment="deleteComment" :comments="content.comments"/>
        </v-card-text>
      </v-card>
    </v-bottom-sheet>
    </div>
  <div class="px-4 py-1"><span>{{content.likes.length}} Like<span v-if="content.likes!=1">s</span></span> <span class="ml-2">{{content.comments.length}} Comment<span v-if="content.comments.length!=1">s</span></span></div>
  <div class="px-4 py-1"><b v-text="content.autor.name"></b> <span v-text="content.text"></span></div>
  <small class="px-4 py-1" v-text="date"></small>
</div>
</template>

<script>
import FeedItem from "@/models/feedItem";
import UserAvatar from "@/components/UserAvatar";
import {addComment, deleteComment, likeOrDislikeFeed} from "@/services/lobby.service";
import CommentsList from "@/components/feed/CommentsList";

export default {
  name: "FeedItem",
  components: {CommentsList, UserAvatar},
  props:{
      content:FeedItem,

  },
  data(){
    return{
      showComments:false
    }
  },
  methods:{
    async deleteItem(id){
      if(confirm("Are you sure?")){
        await this.$store.dispatch("feed/deleteItem",id);
      }
    },
    async addComment(comment){
      let response = await addComment(this.content.id,comment);
      console.log("Repsonse",response)
      if(response){
        let item= this.content;
        item.comments.push(response)
        this.$emit("updateItem",item);
      }
    },
    async deleteComment(commentId) {
      let response = await deleteComment(commentId, this.content.id);
      if(response){
        let item= this.content;
        item.comments= item.comments.filter(x => x.id != commentId);
        this.$emit("updateItem",item);
      }
    },
    like(){
      let success = likeOrDislikeFeed(true,this.content.id);
      let item = this.content;
      if(success) {
        item.likes.push(this.currentUser.id);
        this.$emit("updateItem",item);
      }
    },
    dislike(){
      let success = likeOrDislikeFeed(false,this.content.id);
      let item = this.content;
      if(success) {
        item.likes = item.likes.filter(x=> x != this.currentUser.id);
        this.$emit("updateItem",item);
      }
    }
  },
  computed:{
    currentUser(){
      return this.$store.state.auth.user;
    },
    currentUserLikes(){
      if(this.currentUser) return this.content.likes.includes(this.currentUser.id);
      else return false;
    },
    firstCommentDate(){
      let moment = require("moment");
      return moment(this.content.creationDate).fromNow();
    },
    date(){
      let moment = require("moment");
      return moment(this.content.creationDate).format("LL")
    }
  }
}
</script>

<style scoped>
.el{
  box-shadow: none;
}
@media only screen and (min-width: 768px) {
  .el {
    box-shadow: 0 3px 1px -2px rgba(0,0,0,.2),0 2px 2px 0 rgba(0,0,0,.14),0 1px 5px 0 rgba(0,0,0,.12)!important;
  }
}
</style>