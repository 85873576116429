import { render, staticRenderFns } from "./Favourites.vue?vue&type=template&id=a39bcdc6&scoped=true"
import script from "./Favourites.vue?vue&type=script&lang=js"
export * from "./Favourites.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a39bcdc6",
  null
  
)

export default component.exports