<template>
<v-container>
  <v-col v-for="order of orders" :key="order.uid" cols="12">

    <OrderItem :order="order"/>
  </v-col>

</v-container>
</template>

<script>
import OrderItem from "@/components/order/OrderItem";
export default {
  name: "OrderList",
  components: {OrderItem},
  props:{
    orders:Array
  }
}
</script>

<style scoped>

</style>