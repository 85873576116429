<template>
<a :href="artistLink" :class="{'fullWidth':full}" style="position: relative;overflow: hidden;height:200px;width:200px" class="box custom-border-radius" >
<v-img style="pointer-events: none;" class="avatar" :src="artist.profilePicture?'data:image/webp;base64,'+artist.profilePicture :require('@/assets/Resize_Placeholder-Profile-819x1024.webp')"
></v-img>
  <div style="position: absolute;bottom:0;width:100%;" class="pa-4 text-center background-name">
    <span class="text-uppercase" style="color: white;">{{artist.name}}</span>
    <v-tooltip v-if="companies && companies.length>0" bottom>
      <template v-slot:activator="{attrs,on}">
       <div v-on="on" v-bind="attrs" style="max-width: 100%;color:white;" class="text-truncate">
         <div v-if="companies" class="mx-auto" style="margin:5px 0;width: 80%;border-bottom: 1px solid white"></div>
        <span class="text-truncate" v-for="(company,$index) of companies" :key="company"><span v-text="company"></span><span v-if="$index<companies.length-1" class="mr-1">,</span></span>
       </div>

      </template>
      <span style="color: white;" v-for="(company,$index) of companies" :key="company"><span  v-text="company"></span><span v-if="$index<companies.length-1" class="mr-1">,</span></span>

    </v-tooltip>
  </div>

</a>
</template>

<script>

export default {
  name: "ArtistItemPublic",
  props:{
    artist:Object,
    anonym:Boolean,
    full:Boolean
  },
  computed:{
    companies(){
      return this.artist.professions.map(x => x.company.name);
    },
    artistLink(){
      if(this.anonym) return "/u/artist/"+this.artist.id;
      else return "/artist/"+this.artist.id

    },
  },
}
</script>

<style scoped>
.box:hover .avatar{
  transform: scale(1.2);
}
.fullWidth{
  width:100%!important;
  height:300px!important;
}
.avatar{
  transition: transform 0.5s ease;
  cursor: pointer;
  width:100%;
  height: 100%;
  position: absolute;
}
.avatar:hover{
}
.background-name{
  background: rgba(0, 0, 0, 0.6);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(11.8px);
  -webkit-backdrop-filter: blur(11.8px);
  opacity:0.7;
}
</style>