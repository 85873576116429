<template>
  <v-sheet style="position: relative">
      <WelcomeUserEmail>
        <template #additionalContent>
          <v-textarea placeholder="add custom text..." v-model="text" solo rows="5"></v-textarea>
        </template>
      </WelcomeUserEmail>
    <v-toolbar class="" style="position: sticky;bottom:0;" bottom>
      <v-btn :disabled="loading" @click="close">Cancel</v-btn>
      <v-spacer/>
      <v-btn :loading="loading" @click="sendEmail">Send</v-btn></v-toolbar>
  </v-sheet>
</template>
<script>
import WelcomeUserEmail from "@/components/WelcomeUserEmail"
import {sendAccountWelcomeEmail} from "@/services/user.service";

export default {
  name: 'SendAccountEmail',
  components: {WelcomeUserEmail},
  props:{
    userId:Number
  },
  data(){
    return{
      text:"",
      loading:false,
    }
  },
  methods:{
    sendEmail(){
      this.loading=true;
      if(confirm("Attention! By sending the mail the users password gets reset. The new Password will be sent in the Mail")) sendAccountWelcomeEmail(this.userId,this.text)
      this.close();
    },
    close(){
      this.$emit("close");
    }
  }
}
</script>
