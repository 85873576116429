export default class CreateUser{
    firstName;
    lastName;
    name;
    bio;
    role;
    company;
    email;
    profilePic;
    website;
    spotify;
    soundcloud;
    instagram;
    youtube;
    linkedIn;
    twitter;
    genre;

    constructor() {
        this.firstName=null;
        this.lastName=null;
        this.name=null;
        this.role=null;
        this.company=null;
        this.bio=null;
        this.email=null;
        this.profilePic=null;
        this.website=null;
        this.spotify=null;
        this.soundcloud=null;
        this.instagram=null;
        this.youtube=null;
        this.linkedIn=null;
        this.twitter=null;
        this.genre=[]

    }
}