<template>
  <div style="position: relative" class="pa-5">
  <h2>My Tickets</h2>
<TicketList/>
    <v-btn fab style="position: fixed; bottom:20px;right:20px" @click="$router.push('/ticket/redeem')"><v-icon>mdi-plus</v-icon></v-btn>
  </div>
</template>

<script>
import TicketList from "@/components/ticket/TicketList";
export default {
  name: "MyTickets",
  components: {TicketList}
}
</script>

<style scoped>

</style>