<template>
<v-list>
  <PressKitUser v-for="pressUser of pressKitUsers" :key="pressUser.user.id" :user="pressUser.user" :kit="pressUser.pressKit"/>
</v-list>
</template>

<script>
import PressKitUser from "@/components/press/PressKitUser";

export default {
  name: "PressUserList",
  components: {PressKitUser},
  props:{
    pressKitUsers:Array
  }
}
</script>

