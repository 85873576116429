import AuthService from '../services/auth.service';
import {acceptPrivacy, loadSettings} from "@/services/user.service";
import {follow, unfollow} from "@/services/follow.service";

const user = JSON.parse(localStorage.getItem('user'));
const setting = JSON.parse(localStorage.getItem('setting'));
const initialState = user
    ? { status: { loggedIn: true }, user, settings:setting }
    : { status: { loggedIn: false }, user: null };

export const auth = {
    namespaced: true,
    state: initialState,
    actions: {
        removeProfession({commit},professionId){
          commit('removeProfession',professionId);
        },
        updateLocalUser({commit},user){
            commit('updateLocalUser',user)
        },
        login({ commit }, user) {

            return AuthService.login(user).then(
                async user => {
                    commit('loginSuccess', user);
                    let setting = await loadSettings();
                    commit("setSettings", setting);
                    return Promise.resolve(user);
                },
                error => {
                    commit('loginFailure');
                    return Promise.reject(error);
                }
            );
        },
        loadSettings({commit}){
          let response = loadSettings();
          if(response){
              commit('setSettings',response)
          }
        },
        setSettings({commit},setting){
          commit('setSettings',setting);
        },
        loginCognito({ commit }, tokenObject) {

            return AuthService.loginCognito(tokenObject).then(
                async user => {
                    commit('loginSuccess', user);
                    let setting = await loadSettings();
                    commit("setSettings",setting);
                    return Promise.resolve(user);
                },
                error => {
                    commit('loginFailure');
                    return Promise.reject(error);
                }
            );
        },
        logout({ commit }) {
            AuthService.logout();
            commit('logout');
        },
        register({ commit }, user) {
            return AuthService.register(user).then(
                user => {
                    localStorage.setItem('user', JSON.stringify(user.data));
                    commit('loginSuccess', user.data);
                    return Promise.resolve(user);
                },
                error => {
                    commit('registerFailure');
                    return Promise.reject(error);
                }
            );
        },
        async acceptPrivacy({commit}) {
            let success = await acceptPrivacy();
            if(success){
                commit("acceptPrivacy",true);
                return true;
            }
            else{
                return false;
            }
        },
        registerFromCart({commit},request){
            console.log("Request,module",request);
          return AuthService.registerFromCart(request.signupRequest,request.cart).then(
              user => {
                  localStorage.setItem('user', JSON.stringify(user.data));
                  commit('loginSuccess', user.data);
                  return Promise.resolve(user);
              },
              error => {
                  commit('registerFailure');
                  return Promise.reject(error);
              });
        },
        disableFirstLogin({commit}){
            commit("setFirstLogin")
        },
        async follow({commit}, userId) {
            let success = await follow(userId);
            if(success){
                commit("follow",userId);
            }else{
                console.log("error");
            }
        },
        async unfollow({commit}, userId) {
            let success = await unfollow(userId);
            if(success){
                commit("unfollow",userId);
            }
            else{
                console.log("error");
            }
        },
        addRole({commit},role){
            commit("addRole",role);
        }
    },
    mutations: {
        removeProfession(state,id){
          state.user.professions.splice(state.user.professions.findIndex(x=> x.id ===id),1);
            localStorage.setItem('user', JSON.stringify(state.user));
        },
        addRole(state,role){
          state.user.roles.push(role);
            localStorage.setItem('user', JSON.stringify(state.user));

        },
        acceptPrivacy(state,status){
          let user = state.user;
          user.acceptedPrivacyPolicy=status;
          localStorage.setItem('user', JSON.stringify(user));
        },

        follow(state,userId){
            let user = state.user;
            user.followedUsers.push(userId);
            localStorage.setItem('user', JSON.stringify(user));
        },
        unfollow(state,userId){
            let user = state.user;
            console.log("H",user.followedUsers)
            user.followedUsers.splice(user.followedUsers.indexOf(userId),1);
            console.log("new followedUsers",user.followedUsers);
            localStorage.setItem('user', JSON.stringify(user));
        },
        setFirstLogin(state){
            state.user.firstLogin = false;
            localStorage.setItem('user', JSON.stringify(state.user));
        },
        updateLocalUser(state,user){
            localStorage.setItem('user', JSON.stringify(user));
            state.user=user;
        },
        async loginSuccess(state, user) {

            state.status.loggedIn = true;
            state.user = user;
        },
        setSettings(state,setting){
            localStorage.setItem('setting', JSON.stringify(setting));
            state.settings=setting;
        },
        loginFailure(state) {
            state.status.loggedIn = false;
            state.user = null;
        },
        logout(state) {
            state.status.loggedIn = false;
            state.user = null;
        },
        registerSuccess(state) {
            state.status.loggedIn = false;
        },
        registerFailure(state) {
            state.status.loggedIn = false;
        }
    }
};