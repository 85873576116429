<template>
  <v-chip-group
      class="centered-chips"
      multiple
      v-model="tags"
      active-class="primary"
      column
      max="10"

  >
    <v-chip
        filter
        v-for="tag of allTags"
        :key="tag.id"
        :value="tag.id"
    >
      {{ tag.name }}
    </v-chip>
  </v-chip-group>
</template>

<script>
import ApiService from "@/services/api.service";

export default {
  name: "UpdateTags",
  props:{
    tagsProp:Array
  },
  data(){
    return{
      allTags:new Array()
    }
  },
  created() {
    this.getTags();
  },
  methods:{
    getTags(){
      let self=this;
      ApiService.get("tags/get/all").then(response => {
        self.allTags=response.data;
      });
    }
  },
  computed:{
    tags:{
      get(){
        return this.tagsProp;
      },
      set(value){
        this.$emit("update",value);
      }
    }
  }
}
</script>

<style scoped>

</style>