<template>
<div>
  <p class="text-center font-weight-bold">Finally, connecting with your socials</p>
  <v-text-field type="url" prepend-icon="mdi-facebook" @change="updateUser(currentUser)" v-model="currentUser.facebook" solo dense flat outlined  placeholder="Facebook"></v-text-field>
  <v-text-field type="url" prepend-icon="mdi-youtube" @change="updateUser(currentUser)" v-model="currentUser.youtube" solo dense flat outlined  placeholder="Youtube"></v-text-field>
  <v-text-field type="url" prepend-icon="mdi-instagram" @change="updateUser(currentUser)" v-model="currentUser.instagram" solo dense flat outlined  placeholder="Instagram"></v-text-field>
  <v-text-field type="url" prepend-icon="mdi-soundcloud" @change="updateUser(currentUser)" v-model="currentUser.soundcloud" solo dense flat outlined  placeholder="Soundcloud"></v-text-field>
  <v-text-field type="url" prepend-icon="mdi-spotify" @change="updateUser(currentUser)" v-model="currentUser.spotify" solo dense flat outlined  placeholder="Spotify"></v-text-field>
  <v-text-field type="url" prepend-icon="mdi-twitter" @change="updateUser(currentUser)" v-model="currentUser.twitter" solo dense flat outlined  placeholder="Twitter"></v-text-field>

</div>
</template>

<script>
import {updateUser} from "@/services/user.service";

export default {
  name: "SocialInformation",
  computed:{
    currentUser(){
      return this.$store.state.auth.user;
    }
  },
  methods: {
    updateUser(user) {
      updateUser(user);
    },
  }
}
</script>

<style scoped>

</style>