import ApiService from '@/services/api.service'

export async function getFullManagementResponse() {
    let response = await ApiService.get("management/full");
    return response.data;
}

export function exportToExcel(users) {
    let csvContent = "data:text/csv;charset=utf-8," +
        "Name,Email,Professions,Festival/Conference\n";

    users.forEach(function(user) {

        let professions = user.professions.map(function(profession) {
            return '(' + profession.company.name + ')';
        }).join(';');

        let festivalConference = '';
        if (user.festival && user.conference) {
            festivalConference = 'Both';
        } else if (user.festival) {
            festivalConference = 'Festival';
        } else if (user.conference) {
            festivalConference = 'Conference';
        }

        let row = [
            user.name,
            user.email,
            professions,
            festivalConference
        ];

        if(user.visibleOnWebsite) csvContent += row.join(',') + '\n';
    });

    let encodedUri = encodeURI(csvContent);
    let link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "user_data.csv");
    document.body.appendChild(link);
    link.click();
}