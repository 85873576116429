/* eslint-disable no-console */
import ApiService from './services/api.service'
import store from "./store"
import Note from "@/models/notification";
import {updateUser} from "@/services/user.service";
//import {decode} from "base64-arraybuffer";

if ('serviceWorker' in navigator) {
  window.addEventListener('load', function() {
    navigator.serviceWorker.register('/service-worker.js', {
      ready () {
        console.log(
            'App is being served from cache by a service worker.\n' +
            'For more details, visit https://goo.gl/AFskqB'
        )
      },
      registered () {
        console.log('Service worker has been registered.')
      },
      cached () {
        console.log('Content has been cached for offline use.')
      },
      updatefound () {
        console.log('New content is downloading.')
      },
      updated () {
        console.log('New content is available; please refresh.')
      },
      offline () {
        console.log('No internet connection found. App is running in offline mode.')
      },
      error (error) {
        console.error('Error during service worker registration:', error)
      }
    });
  });
}
function isPushApiSupported() {
  return 'PushManager' in window;
}

  //Request Push Notification Persmission/
  if ('Notification' in window && Notification.permission != 'granted') {
    console.log('Ask user permission')
    Notification.requestPermission(status => {
      console.log('Status:'+status)
      displayNotification('Notification Enabled');
    });
  }


  const displayNotification = notificationTitle => {
    console.log('display notification')
    if (Notification.permission == 'granted') {
      navigator.serviceWorker.getRegistration().then(reg => {
        console.log(reg)
        const options = {
          body: 'Thanks for allowing push notification !',
          icon:  '/assets/icons/icon-512x512.png',
          vibrate: [100, 50, 100],
          data: {
            dateOfArrival: Date.now(),
            primaryKey: 0
          }
        };

        reg.showNotification(notificationTitle, options);
      });
    }
  };

  const updateSubscriptionOnYourServer = subscription => {
    //console.log('Write your ajax code here to save the user subscription in your DB', subscription);
    // write your own ajax request method using fetch, jquery, axios to save the subscription in your server for later use.

    ApiService.post("user/subscription/save",subscription).then(response => {
      if(response.data){
        console.log("Saved Subscription on server");
        ApiService.post("user/subscription/add",response.data).then(user => {
          if(user.data){
            console.log("Added Subscription to User");
            updateUser(user.data);
          }
          else{
            store.dispatch("notificationsStore/add",new Note("warning","Connection to Notification Sevrice Failed. Try Reconnecting",false))

          }
        })
      }else{
        console.log("Subscription already present");
        }
    })
    store.state.notificationsStore.endpoint=subscription;
  };
    const subscribeUser = async () => {
      const swRegistration = await navigator.serviceWorker.getRegistration();
      const applicationServerPublicKey = await ApiService.get("push/publicKey/get");
      const applicationServerKey = applicationServerPublicKey.data;
      swRegistration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey
      })
          .then((subscription) => {
            console.log('User is subscribed newly:', subscription);
            if(store.state.auth.status.loggedIn) {
              updateSubscriptionOnYourServer(subscription);
            }
          })
          .catch((err) => {
            if (Notification.permission === 'denied') {
              console.warn('Permission for notifications was denied')
            } else {
              console.error('Failed to subscribe the user: ', err)
            }
          });
    };
  /**
  const urlB64ToUint8Array = (base64String) => {
    const padding = '='.repeat((4 - base64String.length % 4) % 4)
    const base64 = (base64String + padding)
        .replace(/-/g, "+")
        .replace(/_/g, "/");

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
  };*/


  const checkSubscription = async () => {
    const swRegistration = await navigator.serviceWorker.getRegistration();
    swRegistration.pushManager.getSubscription()
        .then(subscription => {
          if (subscription) {
            console.log('User IS Already subscribed.');
            updateSubscriptionOnYourServer(subscription);
          } else {
            console.log('User is NOT subscribed. Subscribe user newly');
            subscribeUser();
          }
        });
  };

  if(store.state.auth.status.loggedIn && isPushApiSupported()) {
    checkSubscription();
  }


