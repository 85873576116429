export default class Event{
    id;
    title;
    description;
    dateTime;
    end;
    ticketLink;
    conference;
    genres;
    thumbnail;
    artists;
    year;
    createdAt;
    venue;
    savedByIds;
    conferenceTopics;
    conferenceType;
//TODO Event and Artist need list of year in wich they where active, or only EVents and getAllArtists where Event = year
    constructor() {
        this.title = null;
        this.description=null;
        this.thumbnail=null;
        this.dateTime = null;
        this.venue=null;
        this.end=null;
        this.ticketLink = null;
        this.festivalOrConference = null;
        this.artists = null;
        this.genres=new Array();
        this.createdAt=new Date();
        this.savedByIds = new Array();
        this.conferenceTopics= new Array();
        this.conferenceType= null;
    }
}