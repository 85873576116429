import authHeader from "@/services/auth-header";
import axios from "axios";
import host from "./host";
import store from "@/store";
import Notification from "@/models/notification";
const API_URL = host+'/api/';
//const API_URL = 'https://deep-diverse.com:8443/api/';
class ApiService {
    get(url) {
        return axios.get(API_URL + url, {headers: authHeader()}).catch(function (error) {

            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                if (error.response.status == 401) {
                    store.dispatch("notificationsStore/add", new Notification("error", "You are not logged in.", false,null,"/login","Login"))
                    store.dispatch("auth/logout");

                }

                else if(error.response.status==500 && error.response.data.message){
                    store.dispatch("loading/error");
                    console.log("TEst",error)
                    store.dispatch("notificationsStore/add", new Notification("error",error.response.data.message,false))
                }
                else if(error.response.data.message){
                    store.dispatch("loading/error");
                    store.dispatch("notificationsStore/add", new Notification("error",error.response.data.message,false))

                }
            }});
    }
    getUnauthorized(url) {
        return axios.get(API_URL + url);
    }postUnauthorized(url,data) {
        return axios.post(API_URL + url,data);
    }

    post(url, data) {
        // eslint-disable-next-line no-console
        return axios.post(API_URL+url,data,{headers: authHeader()}).catch(handleError);
    }
    getWithoutApi(url) {
        return axios.get(host + "/" + url, {headers: authHeader()});
    }
    postWithoutApi(url, data) {
        // eslint-disable-next-line no-console
        return axios.post(host + "/" + url, data, {headers: authHeader()})
    }
}

function handleError(error){

        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            if (error.response.status == 401) {
                store.dispatch("notificationsStore/add", new Notification("error", "You are not logged in.", false,null,"/login","Login"))
                store.dispatch("auth/logout");

            }

            else if(error.response.status==500 && error.response.data.message){
                store.dispatch("loading/error");
                console.log("TEst",error)
                store.dispatch("notificationsStore/add", new Notification("error",error.response.data.message,false))
            }
            else if(error.response.data.message){
                store.dispatch("loading/error");
                store.dispatch("notificationsStore/add", new Notification("error",error.response.data.message,false))

            }
        }
}
export default new ApiService();
