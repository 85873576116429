<template>
<div>
  <v-btn @click="subscribe">Subscribe</v-btn>
  <v-btn @click="unsubscribe">UnSubscribe</v-btn>
  <v-btn @click="send">Send sample push</v-btn>
  <v-btn @click="sendNormalNotification">Send Normal Notification</v-btn>
</div>
</template>

<script>
import ApiService from "@/services/api.service";
import Notification from "@/models/notification";

export default {
  name: "PushNotificationTest",
  methods:{
    subscribe(){
      ApiService.post("push/subscribe",this.$store.state.notificationsStore.endpoint).then(response => {
        console.log("SubscribeResponse",response);
      });
    },
    unsubscribe(){
      ApiService.post("push/unsubscribe",this.$store.state.notificationsStore.endpoint.endpoint).then(response => {
        console.log("UnSubscribeResponse",response);
      });
    },
    send(){
      ApiService.post("push/send", {
        user: this.$store.state.auth.user,
        notificationRequest: {
          title:"Test",
          text:"This is a Test"
        }
      }).then(response => {
        console.log("response",response)
      })
    },
    sendNormalNotification(){
      this.$store.dispatch("notificationsStore/add", new Notification("error", "You are not logged in.", false,"Heute keine Schnullis meine bres","/login","Login"))

    }

  },

  created() {
    }
}
</script>

<style scoped>

</style>