import ApiService from "@/services/api.service";

export async function uploadFiles(files,userId){
    let response = await ApiService.post("press/files/"+userId+"/upload",files);
    return response.data;
}

export async function getAllPressKits(){
    let response = await ApiService.get("press/kit/all");
    return response.data;
}

export async function deletePressKitItem(id){
    let response = await ApiService.post("press/kit/"+id+"/delete");
    return response.data;
}

export async function downloadZip(userId){
    let response = await ApiService.get("press/kit/"+userId+"/zip");
    if(response.data){
        console.log(response.data);
        download(response.data,"Pressfiles.zip");
    }
}

export async function loadKitOfUser(userId){
    let response = await ApiService.get("press/kit/"+userId+"/load");
    console.log(response.data);
    return response.data;
}

export async function downloadFile(userId,imageId){
    let response = await ApiService.get("press/kit/"+userId+"/"+imageId+"/download");
    if(response.data){
        downloadPressKitItem(response.data);
    }
    else return null;
}


function downloadPressKitItem(pressKitItem) {
    // Convert byte array to Blob
    console.log("PressKitItem",pressKitItem);

    // Create a temporary URL for the Blob
    const url = 'data:'+pressKitItem.type+';base64,'+pressKitItem.file;

    // Create a link element
    const link = document.createElement('a');
    link.href = url;
    link.download = pressKitItem.name;

    // Append the link to the body
    document.body.appendChild(link);

    // Trigger a click event on the link to initiate the download
    link.click();

    // Clean up
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
}

function download(zipFileBase64Encoded, fileName) {
    // Create download link
    const link = document.createElement('a');
    link.href = 'data:application/zip;base64,'+zipFileBase64Encoded;
    link.download = fileName; // Set the filename
    document.body.appendChild(link);

    // Trigger the download
    link.click();

    // Clean up
    document.body.removeChild(link);
}