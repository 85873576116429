<template>
<div class="mainContent">
  <network v-if="networkGraph" style="width:100%;height: 100%" ref="network"
           :nodes="networkGraph.nodes"
           :edges="networkGraph.edges"
           @select-node="showUserEdges"
           :options="options">
  </network>
  <v-btn style="position:fixed;bottom:0" @click="focus">Focus</v-btn>
</div>
</template>

<script>

import { Network } from "vue-vis-network";
import {buildNetwork} from "@/services/algorythm.service";
export default {
  name: "MyNetwork",
  components:{Network},
  data() {
    return {
      nodes: [
        {id: 1,  label: 'circle',  shape: 'circle' },
        {id: 2,  label: 'ellipse', shape: 'ellipse'},
        {id: 3,  label: 'database',shape: 'database'},
        {id: 4,  label: 'box',     shape: 'box'    },
        {id: 5,  label: 'diamond', shape: 'diamond'},
        {id: 6,  label: 'dot',     shape: 'dot'},
        {id: 7,  label: 'square',  shape: 'square'},
        {id: 8,  label: 'triangle',shape: 'triangle'},
      ],
      edges: [
        {from: 1, to: 2},
        {from: 2, to: 3},
        {from: 2, to: 4},
        {from: 2, to: 5},
        {from: 5, to: 6},
        {from: 5, to: 7},
        {from: 6, to: 8}
      ],
      options: {
        groups:{
          Elephant: {
            color:{
              border:'red'
            },borderWidth:3}
        },
        nodes: {
          borderWidth: 1,

        },
        physics: {
          enabled: true,

          barnesHut: {
            gravitationalConstant: -4000,
            centralGravity: 0.3,
            springLength: 95,
            springConstant: 0.01,
            damping: 0.09,
            avoidOverlap: 1
          },
        },
        edges: {
          color: {
            color:'lightgray',
            highlight:'black',
            opacity:1
          },

        }
      },
      networkGraph:null
    }
  },
  created() {

    this.networkGraph=buildNetwork(this.$store.state.auth.user);
  },
  methods:{
    focus(){
      this.$refs.network.focus(this.$store.state.auth.user.id);
    },
    showUserEdges(event){
      let node=event.nodes[0]
      for(let edge of this.networkGraph.edges){
        if(edge.from != this.$store.state.auth.user.id) edge.hidden=true;
       if(edge.from ===node){
         edge.hidden=false;
       }
      }

    }
  }
}
</script>

<style scoped>

</style>