<template>
  <v-chip-group
      class="centered-chips"
      multiple
      v-model="selectedGenre"
      active-class="primary"
      column
      max="10"

  >
    <v-chip
        filter
        v-for="item of genres"
        :key="item.id"
        :value="item.id"
    >
      {{ item.name }}
    </v-chip>
  </v-chip-group>
</template>
<script>
import {getAllGenres} from "@/services/genre.service";

export default {
  name: 'GenrePicker',
  watch:{
    selectedGenre(){
      console.log("Update",this.selectedGenre);
      this.$emit('update',this.selectedGenre);
    }
  },
  props:{
    genreToEdit:Array
  },
  data(){
    return{
      genres:[],
      selectedGenre:[]
    }
  },
  async created() {
    if(this.genreToEdit && this.genreToEdit.length>0){
      this.selectedGenre = this.genreToEdit;
    }
    await this.getAllGenres();
  },
  methods:{
    async getAllGenres() {
      this.genres = await getAllGenres();
    },
  }
}
</script>
