<template>
  <div>

    <v-text-field v-model="search" solo class="custom-border-radius mx-5" label="Search..." prepend-inner-icon="mdi-magnify"/>
    <div style="width:100%" class="d-flex justify-space-between px-5">
      <ClearCache/>
      <v-btn :loading="loading" x-small @click="getMyEvents">Refresh <v-icon x-small>mdi-refresh</v-icon></v-btn>
    </div>
<v-list>
  <v-list>
    <v-dialog v-model="showCreateDialog" v-if="isAdmin">
      <template #activator="{attrs,on}">
        <v-list-item v-on="on" v-bind="attrs" link class="offwhite mx-5 my-2">
          <v-list-item-icon>
            <v-icon>mdi-plus</v-icon>
          </v-list-item-icon>
          <v-list-item-title class="font-weight-bold mr-5" style="font-size: 1.3rem;">
            Add new Event
          </v-list-item-title>

        </v-list-item>
      </template>
      <v-sheet>
        <CreateEvent @close="closeDialog"/>
      </v-sheet>
    </v-dialog>

  </v-list>
<AdminEventListItem  @delete="deleteEvent" v-for="event of searchedEvents" :key="event.id" :event="event" @updateEvent="updateEvent"/>

</v-list>

  </div>
</template>

<script>
import {getAllMyEvents} from "@/services/event.service";
import AdminEventListItem from "@/components/management/events/AdminEventListItem";
import CreateEvent from "@/components/event/CreateEvent";
import ClearCache from "@/components/Cache/ClearCache";

export default {
  name: "AdminEventList",
  components: {ClearCache, CreateEvent, AdminEventListItem},
  data(){
    return{
      search:'',
      events:[],
      showCreateDialog:false,
      loading:false,
    }
  },
  created() {
    this.getMyEvents();
  },
  computed:{
    searchedEvents(){
      return this.events.filter(x=> x.event.title.toLowerCase().includes(this.search.toLowerCase())).sort(function(a,b){
        // Turn your strings into dates, and then subtract them
        // to get a value that is either negative, positive, or zero.
        return new Date(b.event.createdAt) - new Date(a.event.createdAt);
      });
    },
    isAdmin(){
      return this.currentUser.roles.includes("ROLE_ADMIN");
    },
    currentUser(){
      return this.$store.state.auth.user;
    }
  },
  methods:{
    closeDialog(){
      this.showCreateDialog=false;
    },
    deleteEvent(id){
      this.events.splice(this.events.findIndex(x=>x.event.id === id),1);
    },
    updateEvent(event){
      //console.log("Event",event);
    //console.log("Event List",this.events);
    let index = this.events.findIndex(x => x.event.id === event.id);
    //console.log("index",index);
    //console.log("eventOld",this.events[index].event);
    this.events[index].event=event;
    //console.log("WithArtists",this.events[index])
      //console.log(eventAndArtist);
      this.$set(this.events,index,this.events[index]);
    },
    async getMyEvents() {
      this.loading=true;
      this.events = await getAllMyEvents();
      this.loading=false;
    }
  }
}
</script>

<style scoped>

</style>