<template>
<div color="transparent" class="transparent">
  <div id="program" style="height:400px; background: linear-gradient(255deg, rgba(131,52,146,1) 0%, rgba(255,255,255,0) 50%), black;" class="d-flex align-center py-0 py-md-15">
    <v-container class="d-flex flex-wrap justify-center align-center">
      <h1 style="width:100%;color: #FFFFFF24;
    font-family: 'Neue Singular H', Sans-serif;
    font-size: 3rem;
    font-weight: 600;
    letter-spacing: 10px;
    -webkit-text-stroke-width: 1px;
    stroke-width: 1px;
    -webkit-text-stroke-color: #FFFFFF;
    stroke: #FFFFFF;" class="text-center text-md-h1 text-uppercase">Program</h1>

    </v-container>
  </div>
  <div class="py-5" style="background-color: var(--v-offwhite-base)">
    <v-layout wrap style="gap:10px;" class="mx-auto container" justify-space-between>
      <v-flex class="align-self-center" xs12 md3>
      <v-chip-group  multiple mandatory v-model="conferenceOrFestival">
        <v-chip active-class="filter-chip-active" value="conference" class="filter-chip" large>Conference</v-chip>
        <v-chip active-class="filter-chip-active" value="festival" class="filter-chip" large>Festival</v-chip>
      </v-chip-group>
      </v-flex>
      <v-flex  class="align-self-center" xs12 md6>
        <v-tabs  style="height: 75px" class="transparent" id="filterTabs" color="transparent" hide-slider :show-arrows="false"
                grow
                v-model="whatToShow"
                active-class="tab-active"
                background-color="transparent"
                height="75"
        >

          <v-tab class="otherTab"   tab-value="events">
            <v-chip class="filter-chip" style="cursor: pointer" large>Events</v-chip>
          </v-tab>
          <v-tab class="otherTab" style="cursor: pointer" tab-value="artists">
            <v-chip active-class="filter-chip-active" style="cursor: pointer" value="artists" class="filter-chip" large><span v-if="conferenceOrFestival.includes('festival')">Artists</span><span v-if="conferenceOrFestival.length>1" class="mx-1"> & </span><span v-if="conferenceOrFestival.includes('conference')">Speaker</span></v-chip>

          </v-tab>
          <v-tab class="otherTab" style="cursor: pointer"  tab-value="venues">
            <v-chip active-class="filter-chip-active" style="cursor: pointer" value="venues" class="filter-chip" large>Venues</v-chip>

          </v-tab>
        </v-tabs>
       </v-flex>
      <v-flex class="ml-1 py-1 d-flex justify-start justify-md-end align-self-center" xs12 md2>

          <v-chip :class="{'filter-chip-active': selectedGenres.length>0 || selectedCTopics.length>0 || selectedVenues.length>0 || selectedDays.length>0 || selectedCTypes.length>0}" @click="selectGenre = !selectGenre" class="filter-chip" large>

            <v-icon class="mr-1">mdi-tune-vertical</v-icon> Filter

          </v-chip>
       </v-flex>
      <v-flex cols="12">
        <v-text-field class="" clearable v-model="searchEvent" prepend-inner-icon="mdi-magnify" outlined hide-details placeholder="Search by event, artist or venue"/>

      </v-flex>
    </v-layout>
  </div>
  <v-tabs-items style="max-width: 1200px" class="mx-auto" v-model="whatToShow">
    <v-tab-item value="events">

      <v-list color="transparent" class="events">
        <v-container v-if="filteredEventsGroupedByDay.length<=0">
          <v-skeleton-loader v-if="eventsGroupedByDay.length<=0 && loading" type="card@10"></v-skeleton-loader>
        <div v-if="!loading && filteredEventsGroupedByDay.length<=0">
          <h3 class="text-center">Nothing matches your search :(</h3>
        </div>
        </v-container>
        <div v-else style="position: relative">
          <v-container  v-for="day of filteredEventsGroupedByDay" :key="day.day">
            <v-list>
              <v-list-group
                  :value="true"
              >
                <template v-slot:activator>
                  <v-list-item-title style="">
                    <h3 class="text-uppercase text-color-accent  mt-2">{{dateToday(day.day)}}</h3>
                  </v-list-item-title>
                </template>

            <v-img style="z-index:-1;opacity:0.05;position: absolute;left:0;bottom:0;width:100%;height:100%;" :src="require('@/assets/wellen-2.webp')"/>


              <div class="py-2" v-for="(event) of day.events" :key="event.id">
                <v-skeleton-loader v-if="loading"
                    type="list-item-two-line@5"
                ></v-skeleton-loader>
                <EventItem :anonym="anonym" :event="event.event" :artists="event.artists" @addToFavourites="(id) => event.savedByIds.push(id)" @removeFromFavourites="(id) => event.savedByIds.splice(event.savedByIds.indexOf(x=> x.id=id),1)"/>
              </div>
              </v-list-group>
              <!--<div class="d-flex justify-center">
                <v-btn :loading="loading" class="mx-auto" @click="loadMoreEventsByDate(day.day)">Load More</v-btn>

              </div>-->
            </v-list>
          </v-container>
        </div>
      </v-list>


    </v-tab-item>
    <v-tab-item value="artists">
      <ArtistListPublic :anonym="anonym" :artists="filteredArtists"/>
    </v-tab-item>
  <!--Artists-->


<v-tab-item value="venues">
    <AllVenues/>

</v-tab-item>
  </v-tabs-items>
  <v-dialog content-class="dialog-top" id="filter-1" scrollable max-width="400px" v-model="selectGenre">
    <v-card class="pa-4">
      <h2 class="text-center">Filter</h2>
      <v-divider class="mb-5"/>
      <v-card-text style="height:500px">
      <div>

      <h3>Days</h3>
      <v-chip-group column multiple v-model="selectedDays">

        <v-chip  class="filter-chip" active-class="filter-chip-active" v-for="day of allDays" :value="day" :key="day">{{weekday(day)}}</v-chip>
      </v-chip-group>
    </div>
      <div>
      <h3>Genres</h3>
      <v-chip-group column multiple v-model="selectedGenres">

        <v-chip  class="filter-chip" active-class="filter-chip-active" v-for="genre of allGenres" :value="genre.id" :key="genre.id">{{genre.name}}</v-chip>
      </v-chip-group>
      </div>

      <div>
        <h3>Conference Type</h3>
        <v-chip-group column multiple v-model="selectedCTypes">

          <v-chip class="filter-chip" active-class="filter-chip-active" v-for="type of allConferenceTypes" :value="type" :key="type">{{type}}</v-chip>
        </v-chip-group>
      </div>
      <div>
        <h3>Conference Topic</h3>
        <v-chip-group column multiple v-model="selectedCTopics">

          <v-chip  class="filter-chip" active-class="filter-chip-active" v-for="topic of allConferenceTopics" :value="topic" :key="topic">{{topic}}</v-chip>
        </v-chip-group>

      </div>
        <div>
          <h3>Venues</h3>
          <v-chip-group column multiple v-model="selectedVenues">
            <v-chip class="filter-chip" active-class="filter-chip-active" v-for="venue of allVenues" :value="venue.id" :key="venue.id">{{venue.name}}</v-chip>
          </v-chip-group>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
 </div>
</template>

<script>
import ApiService from "../../services/api.service"
import EventItem from "@/views/Event/EventItem";
import { getAllEventsGroupedByDateOfCurrentYear, getEvents} from "@/services/event.service";
import ArtistListPublic from "@/components/artist/ArtistListPublic";
import AllVenues from "@/components/venue/AllVenues";
import {getAllVisibleArtists} from "@/services/artist.service";
export default {
  name: "EventSite",
  components: {AllVenues, ArtistListPublic,  EventItem},
  data(){
    return{
      searchEvent:"",
      selectedGenres:[],
      selectGenre:false,
      whatToShow:"events",
      events:[],
      loading:false,
      searchQuery:"",
      conferenceOrFestival:[
          "festival",
          "conference"
      ],
      selectedVenues:[],
      selectedDays:[],
      allArtists:[],
      selectedCTopics:[],
      selectedCTypes:[]


    }
  },
  async created() {
    //this.getEvents(this.$store.state.events.page);
    await this.getEventsGroupedByDay();
    console.log("events",this.events)
    await this.getAllArtists();

    //this.selectedGenres = JSON.parse(JSON.stringify(this.allGenres.map(x => x.id)));
    //this.selectedCTypes = JSON.parse(JSON.stringify(this.allConferenceTypes));
    //this.selectedCTopics = JSON.parse(JSON.stringify(this.allConferenceTopics));
    //this.selectedDays = JSON.parse(JSON.stringify(this.allDays));



  },
  computed:{
    anonym(){
      return this.$route.meta.hiddenHeaderAndFooter;
    },
    allDays(){
      return this.filteredEventsGroupedByDay.map(x=>x.day);
    },
    allConferenceTypes(){
      let uniqueTypes = new Array();

      this.eventsGroupedByDay.forEach(day => {
        day.events.forEach(event => {
          let type = event.event.conferenceType;
            if (type && uniqueTypes.findIndex(x => x === type) === -1) uniqueTypes.push(type);
        });
      });
      return uniqueTypes;
    },
    allConferenceTopics(){
      let uniqueTopics = new Array();

      this.eventsGroupedByDay.forEach(day => {
        day.events.forEach(event => {
          event.event.conferenceTopics.forEach(topic => {
            if (uniqueTopics.findIndex(x => x === topic) === -1) uniqueTopics.push(topic);
          });
        });
      });
      return uniqueTopics;
    },
    currentUser(){
      return this.$store.state.auth.user;
    },
    filteredArtists(){

      let result= JSON.parse(JSON.stringify(this.allArtists));
      if(this.searchEvent) result = result.filter(x => x.name.toLowerCase().includes(this.searchEvent.toLowerCase()))
      if(!this.conferenceOrFestival.includes("festival")) result = result.filter(x => !x.festival)
      if(!this.conferenceOrFestival.includes("conference")) result = result.filter(x => !x.conference);
      result = result.filter(x => x.genre.findIndex(g => this.selectedGenres.includes(g.id))!=-1 || this.selectedGenres.length<=0);

      return result;
    },
    filteredEventsGroupedByDay(){
      let result = JSON.parse(JSON.stringify(this.$store.state.events.eventsGroupedByDay));
      for(let day of result){
        if(!this.selectedDays.includes(day.day) && !this.selectedDays.length<=0) day.events =[];
        let filteredEvents = day.events;
        if(this.searchEvent) filteredEvents = filteredEvents.filter(x => x.artists.findIndex(a => a.name.toLowerCase().includes(this.searchEvent.toLowerCase())) != -1 || x.event.venue?.name.toLowerCase().includes(this.searchEvent.toLowerCase()) || x.event.title?.toLowerCase().includes(this.searchEvent.toLowerCase()));
        if(!this.conferenceOrFestival.includes("festival")) filteredEvents= filteredEvents.filter(x => x.event.conference);
        if(!this.conferenceOrFestival.includes("conference")) filteredEvents = filteredEvents.filter(x => !x.event.conference);
        if(this.selectedGenres.length>0){
          filteredEvents = filteredEvents.filter(x => (x.event.genres.findIndex(g => this.selectedGenres.includes(g.id))!= -1 || this.selectedGenres.length<=0) && !x.event.conference);
        }
        if(this.selectedVenues.length>0){
          filteredEvents = filteredEvents.filter(x => this.selectedVenues.includes(x.event.venue?.id));
        }

        //TODO if genre selected hide conference and the other way
        if(this.selectedCTopics.length>0) {
          filteredEvents = filteredEvents.filter(x => x.event.conferenceTopics.findIndex(t => this.selectedCTopics.includes(t)) != -1  || this.selectedCTopics.length <= 0)
        }

         if(this.selectedCTypes.length>0) filteredEvents = filteredEvents.filter(x => this.selectedCTypes.includes(x.event.conferenceType) || this.selectedCTypes.length<=0);

          day.events = filteredEvents;

        }

      return result.sort(function(a,b){
        // Turn your strings into dates, and then subtract them
        // to get a value that is either negative, positive, or zero.
        return new Date(a.day) - new Date(b.day);
      });
    },

    allGenres(){
      let uniqueGenres = new Array();

        this.eventsGroupedByDay.forEach(day => {
          day.events.forEach(event => {
            event.event.genres.forEach(genre => {
              if (uniqueGenres.findIndex(x => x.id === genre.id) === -1) uniqueGenres.push(genre);
            });
          });
        });

       this.allArtists.forEach(artist => {
         artist.genre.forEach( genre => {
           if(uniqueGenres.findIndex(x => x.id === genre.id) === -1) uniqueGenres.push(genre);
         })
       })
      return uniqueGenres;
    },
    allVenues(){
      let uniqueVenues = new Array();
      this.filteredEventsGroupedByDay.forEach(day =>{
        day.events.forEach(event => {
          if(event.event.venue && uniqueVenues.findIndex(x => x.id === event.event.venue.id)===-1) uniqueVenues.push(event.event.venue);
        });
      });
      return uniqueVenues;
    },
    eventsGroupedByDay(){
      return this.$store.state.events.eventsGroupedByDay;
    },
    groupEventsByDate() {
      let moment = require('moment'); // require
      return this.filteredEvents.reduce(function(groups, item) {
        var name = moment(item.event.dateTime).format('dddd[,] DD[.]MM');
        var group = groups[name] || (groups[name] = []);
        group.push(item);
        return groups;
      }, { });
    },
  },
  methods:{
    async getAllArtists() {
      /**let uniqueArtists = new Array();
       this.filteredEventsGroupedByDay.forEach(day =>{
          day.events.forEach(event => {
            event.artists.forEach(artist => {
              if(uniqueArtists.findIndex(x => x.id === artist.id) === -1)uniqueArtists.push(artist);
            });
          });
      });
       return uniqueArtists;**/
      let response = await getAllVisibleArtists();
      this.allArtists= response;
    },
    weekday(day){
      let moment =  require('moment');
      return moment(day).format('dddd');
    },
    filterEventsOfDay(events){
      return events;
    },
    dateToday(date){
      let moment = require('moment');
      return moment(date).format('DD[.]MM dddd')
    },
    loadMoreEventsByDate(date){
      this.$store.dispatch("events/loadAllEventsOfDate",date);
    },
    async getEventsGroupedByDay(){
      this.loading = true;
      let response = await getAllEventsGroupedByDateOfCurrentYear();
      this.$store.state.events.page++;
      this.$store.dispatch("events/setEventsGroupedByDate", response);
      this.loading = false;
    },
    async getEvents(page) {
      this.loading = true;
      let response = await getEvents(page);
      this.$store.state.events.page++;
      this.$store.dispatch("events/setEvents", response);
      this.loading = false;
    },
    getEventsUnauthorized(){
      this.loading=true;
      let self=this;
      ApiService.getUnauthorized("event/inkognitoEvents").then(response => {
        self.$store.dispatch("events/setEvents",response.data.sort(function(a,b){
          // Turn your strings into dates, and then subtract them
          // to get a value that is either negative, positive, or zero.
          return new Date(a.eventDatumTime) - new Date(b.eventDatumTime);
        }));
        this.loading=false;
      })
    }
  }
}
</script>

<style scoped>
.filter-chip{
  padding:1em 2em;
  opacity:0.3;
}
.filter-chip-active{
  opacity: 1;
}
.tab-active .filter-chip{
  opacity:1;
}

</style>