<template>
  <div>
  <v-banner :color="color"><h3>Order <span v-text="orderState"></span></h3></v-banner>
  <OrderList :orders="orders"/>
  </div>
</template>

<script>
import OrderList from "@/components/order/OrderList";
export default {
  name: "AfterPayment",
  props:{
  },
  components: {OrderList},
  computed:{
    color(){
      if(this.orderState==='completed') return "success";
      else if(this.orderState==='pending') return "warning";
      else return "grey";
    },
    orderId(){
      if(this.order) return this.order.split("/")[this.order.split("/").length-1];
      else return null
    },
    orderState(){
      return this.$route.query.order_state;
    },
    order(){
      return this.$route.query.order;
    },
    orderToken(){
      return this.$route.query.order_token;
    },
    orders(){
      return [...this.$store.state.cart.orders].sort((x) => {
        if(x.uid === this.orderId) return -1;
        else return 0;
      });
    }
  },
  created() {
    this.updateOrders();
  },
  methods:{
    updateOrders(){
      this.$store.dispatch("cart/updateOrders");
    }
  }
}
</script>

<style scoped>

</style>