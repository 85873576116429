<template>
<div>
  <UploadPressFiles/>
</div>
</template>

<script>
import UploadPressFiles from "@/components/press/UploadPressFiles";
export default {
  name: "PressView",
  components: {UploadPressFiles}
}
</script>

<style scoped>

</style>