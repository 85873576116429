import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'

//import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify)
import de from "vuetify/lib/locale/de";
import en from "vuetify/lib/locale/en";
const opts = {
    lang: {
        locales: { de,en },
        current: 'en',
    },
    theme: {
        options: {
            customProperties: true,
        },
        themes: {
            light: {
                primary: '#1A1A1A',
                secondary: '#FFFFFF',
                accent: '#DB54EF',
                error: '#FF5252',
                info: '#104380',
                success: '#4CAF50',
                warning: '#FFC107',
                text: '#F2F2F2',
                offwhite: '#f5f5f5',
                white: '#FFFFFF',
                grey: '#ADADAD',
                whitevariant: '#F4F4F4',
                lightblack:'#1A1A1A',
                background:'#e5e5e5'
            },
            dark: {
            primary: '#FFFFFF',
            secondary: '#1e1e1e',
            whitevariant: '#292929',
            background: '#e5e5e5'
                            },
        },
    }

}



export default new Vuetify(opts)