<template>
  <v-form ref="form" class="mx-5 d-flex flex-wrap" style="gap:15px" v-model="valid">

    <v-text-field
        autofocus
        solo
        rounded
        v-if="!small"
        label="Enter First Name"
        v-model="user.firstName"
        required
    />
    <v-text-field
        autofocus
        v-if="!small"
        solo
        rounded
        label="Enter Last Name"
        v-model="user.lastName"
        required
    />
    <v-text-field
        autofocus
        solo
        rounded
        label="Enter Artist- /Nickname"
        v-model="user.name"
        :counter="20"
        :rules="usernameRules"
        required
    />
    <v-text-field
        solo
        type="email"
        rounded
        label="Enter your email address"
        v-model="user.email"
        :rules="emailRules"
        required
    />
    <v-select v-if="gender" solo :items="[{name:'Female',value:'2'},{name:'Male',value:'1'},{name:'Diverse',value:'9'}]" v-model="user.gender" label="Gender" item-value="value" item-text="name"/>
    <v-dialog
        ref="dialog"
        v-model="show"
        :return-value.sync="user.dateOfBirth"
        persistent
        width="290px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
            solo
            v-if="birthDate"
            v-model="user.dateOfBirth"
            label="Date of Birth"
            prepend-inner-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
        ></v-text-field>
      </template>
      <v-date-picker
          v-model="user.dateOfBirth"
          scrollable
      >
        <v-spacer></v-spacer>
        <v-btn
            text
            color="primary"
            @click="modal = false"
        >
          Cancel
        </v-btn>
        <v-btn
            text
            color="primary"
            @click="$refs.dialog.save(user.dateOfBirth)"
        >
          OK
        </v-btn>
      </v-date-picker>
    </v-dialog>
    <div style="width:100%">
      <EditAddress @updateAddress="updateAddress" v-if="address"/>

    </div>
    <v-text-field
        solo
        rounded
        autocomplete="current-password"
        :value="userPassword"
        label="Enter password"
        :hint="$t('registration.passwordCheck')"
        :append-icon="value ? 'mdi-eye' : 'mdi-eye-off'"
        @click:append="() => (value = !value)"
        :type="value ? 'password' : 'text'"
        :rules="[rules.password,rules.required]"
        @input="_=>userPassword=_"
    ></v-text-field>
    <v-text-field
        solo
        autocomplete="current-password"
        :append-icon="value ? 'mdi-eye' : 'mdi-eye-off'"
        @click:append="() => (value = !value)"
        :type="value ? 'password' : 'text'"
        rounded
        label="Confirm password"
        v-model="confirmPassword"
        :rules="[[v => !!v || 'Password is required'], (this.userPassword === this.confirmPassword) || 'Passwords must match']"
        required
    />
    <v-row>
      <v-col class="shrink d-flex align-center">
        <v-checkbox class="ma-0 pa-0" :rules="[v => !!v]" hide-details>

        </v-checkbox>
      </v-col>
      <v-col class="d-flex align-center">
          <span>
            <span>I accept the</span> <a class="mx-1" href="https://berlin-dance-music-event.com/agb/"><span> terms and conditions </span></a> and <a class="mx-1" href="https://berlin-dance-music-event.com/privacy-policy/">data protection
          </a>
            </span>
      </v-col>
    </v-row>
    <slot name="submit">

    </slot>
  </v-form>
</template>

<script>
import User from "@/models/userRegister";
import EditAddress from "@/components/address/EditAddress";

export default {
  name: "RegisterForm",
  components: {EditAddress},
  props:{
    small:Boolean,
    address:Boolean,
    birthDate:Boolean,
    gender:Boolean
  },
  data() {
    return {
      usernameRules: [
        v => !!v || this.$t('registration.nameRequired'),
        v => (v && v.length <= 20) || this.$t('registration.nameLength'),
      ],
      emailRules: [
        v => !!v || this.$t('registration.emailRequired'),
        v => /.+@.+\..+/.test(v) || this.$t('registration.emailValid'),
      ],

      user: new User('', '', '', '', '', ''),
      confirmPassword: "",
      userPassword: "",
      valid: true,
      value: true,
      rules: {
        required: value => !!value || this.$t('registration.passwordRequired'),
        password: value => {
          const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/;
          return (
              pattern.test(value) ||
              this.$t('registration.passwordCheck')
          );
        }
      }
    };
  },
  watch:{
    user:{
      handler: function(val){
        console.log("Hallo",val);
        this.$emit("updateUser",val);
      },
      deep:true

    }
  },
  methods:{
    updateAddress(address){
      this.user.address=address;
    }
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    }
  }
}
</script>

<style scoped>

</style>