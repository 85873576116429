<template>
<div>
  <v-btn small plain class="ma-3 d-none" block @click="download">Download zip</v-btn>
</div>
</template>

<script>
import {downloadZip} from "@/services/press.service";

export default {
  name: "DownloadPressOfUser",
  props:{
    userId:String
  },
  methods:{
    async download(){
      await downloadZip(this.userId);
    }
  }
}
</script>

<style scoped>

</style>