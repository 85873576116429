<template>
  <v-list-item :class="{'selected':selected}" @click="selectMethod" class="payment-method py-3 custom-border-radius" style="border:1px solid lightgrey">
    <v-list-item-title class="d-flex justify-start align-center">
      <img :src="paymentMethod.image" :alt="paymentMethod.name" />
      <h3>{{ paymentMethod.name }}</h3>
      <p style="position: absolute;top: 50%;transform: translateY(-50%);right:2em; font-size: 0.5em;" class="pa-2">Fee {{ paymentMethod.costs.amount }} {{ paymentMethod.costs.currency }}</p>
    </v-list-item-title>
  </v-list-item>
</template>

<script>
export default {
  props: {
    selected:Boolean,
    paymentMethod: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      selectedPaymentMethod: null
    };
  },
  methods:{
    selectMethod(){
      this.$emit("select",this.paymentMethod.uid);
    }
  }
};
</script>

<style scoped>
.payment-method {
  margin-bottom: 20px;
}

.payment-method img {
  max-width: 100px;
}
.selected{
  border:5px solid lightgrey!important;
}
</style>
