<template>
  <div>

    <v-form class="elevation-1 pa-5 custom-border-radius" @submit.prevent="submitForm">
      <h2>Upload Files</h2>
      <v-file-input
          :rules="rules"
          v-model="files"
          label="Choose files"
          multiple
      ></v-file-input>
      <v-btn :loading="loading" class="custom-border-radius" block type="submit">Upload</v-btn>
    </v-form>
    <div v-if="!onlyUpload" class="my-5">
    <h3>Your Press Kit</h3>
      <div style="max-height: 400px;overflow-y: scroll;overflow-x: hidden">
    <PressKitItem v-for="item of pressKit" :kit-item="item" @delete="deleteItem" :user-id="userId" :key="item.id"/>
    <DownloadPressOfUser :user="userId"/>
      </div>
    </div>
  </div>
</template>

<script>
import {loadKitOfUser, uploadFiles} from "@/services/press.service";
import DownloadPressOfUser from "@/components/press/DownloadPressOfUser";
import PressKitItem from "@/components/press/PressKitItem";

export default {
  name: "UploadPressFiles",
  components: {PressKitItem, DownloadPressOfUser},
  data(){
    return{
      loading:false,
      files:null,
      pressKit:[],
      rules:[
        files => !files || !files.some(file => file.size > 2e6) || 'File size should be less than 2 MB!'
      ]
    }
  },
  props:{
    onlyUpload:Boolean,
    userId:Number
  },
  async created() {
    this.pressKit = await loadKitOfUser(this.userId);
  },
  computed:{
  },
  methods:{
    deleteItem(id){
      this.pressKit.splice(this.pressKit.findIndex(x => x.id ===id),1);
    },
    async submitForm() {
      this.loading=true;
      let formData = new FormData();
      for (let i = 0; i < this.files.length; i++) {
        let file = this.files[i];
        formData.append('files', file);
      }
      let data = await uploadFiles(formData, this.userId);
      this.pressKit=this.pressKit.concat(data);
      this.loading=false;
    }
  }
}
</script>

<style scoped>

</style>