<template>
  <v-sheet :dark="!event.conference" :class="{'primary': !event.conference, 'offwhite': event.conference}"
          style="position: relative;cursor:pointer;"
           class="my-2 px-5 py-2 elevation-0 custom-border-radius">
<div  @click="$router.push(eventLink)" style="position:absolute; top:0;left:0;width:100%;height:100%"></div>
    <v-row class="px-10 pt-10">
      <v-col v-if="artists" cols="2" class="pa-0 d-md-flex align-center hidden-sm-and-down">
        <div v-if="artists && artists.length>0" class="d-flex justify-start">
          <v-img style="margin-right:-50px" class="hvr-front rounded-circle" max-width="75px" width="75px" height="75px"
                 v-for="(artist) of artistsComputed" :key="artist.id"
                 :src="artist.profilePicture?'data:image/png;base64,'+artist.profilePicture: require('../../assets/Placeholder-Profile-819x1024.webp')"/>
          <v-sheet color="primary" @click="showAll = true" style="margin-right:-50px;z-index:3" class="hvr-front rounded-circle" width="75px"
                   height="75px" v-if="artists.length>3 && !showAll">
            <div style="height:100%;color:white;font-size: 2rem; font-family:'neueSingularH'"
                 class="text-h4 text--white d-flex justify-center align-center align-content-center">+<span style="color:white"
                v-text="artists.length-3"></span></div>
          </v-sheet>
          <div @click="showAll = false" class="d-flex justify-start" v-if="artists.length>3 && showAll">
            <v-img style="margin-right:-50px" class="hvr-front rounded-circle" max-width="75px" width="75px"
                   height="75px" v-for="(artist) of artists.slice(3,artists.length)" :key="artist.id"
                   :src="artist.profilePicture?'data:image/png;base64,'+artist.profilePicture: require('../../assets/Placeholder-Profile-819x1024.webp')"/>

          </div>

        </div>
        <div class="pa-0 d-md-flex justify-center align-center hidden-sm-and-down" v-else>

        </div>
      </v-col>
      <v-col cols="10">
        <v-row class="flex-nowrap d-flex justify-space-between">
            <div style="font-size: 1.4rem;line-height: normal" class="text-uppercase font-weight-bold mr-5" ><span v-text="event.title"></span></div><br/>

          <div class="pa-6 pr-10" style="position: absolute;top:0;right:0;">

            <span v-if="!currentUser">
        <v-btn @click.native.prevent="loginToSaveFavourites" icon><v-icon>mdi-bookmark-outline</v-icon></v-btn>
     </span>
            <v-btn v-else-if="currentUser.favourites?.includes(event.id)" icon @click.native.prevent="removeFavourite(event.id)">
              <v-icon>mdi-bookmark</v-icon>
            </v-btn>
            <v-btn v-else icon @click="addToFavourites(event.id)">
              <v-icon>mdi-bookmark-outline</v-icon>
            </v-btn>
          </div>
        </v-row>
        <v-row>
          <v-col class="col-12 d-flex col-md-6 pa-0" style="white-space: normal;">
            <div :class="{'white-text':!event.conference}"  style="color:#383838;width:100%;opacity:0.8;font-size:1.15rem;font-weight:400;letter-spacing: 1px">
            <div v-if="artists && artists.length>0">
              <small ><span>w/ </span><span class="text-uppercase" v-for="(artist,$index) of artists"
                                           :key="$index">{{ artist.name }}<span
                  v-if="$index<artists.length-1">, </span></span></small>
            </div>
              <div v-else><small>Artists to be announced.</small>
              </div>
            </div>
          </v-col>
          <v-col  v-if="event.savedByIds && event.savedByIds.length>0"  cols="12" class="col-md-6">
            <div v-if="currentUser" style="z-index:99">
              <UserPopover tile v-for="(user,index) in event.savedByIds.slice(0,9)" :key="user.id" :user="user" :index=index>

              </UserPopover>
              <v-menu
                  v-if="event.savedByIds.length>9"
                  bottom
                  max-height="200px"
                  origin="center center"
                  transition="slide-y-transition"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" class="align-self-center pl-0" plain text x-small>+{{ event.savedByIds.length - 9 }} more</v-btn>
                </template>
                <v-list>
                  <UserPopover v-for="user of event.savedByIds" :key="user.id" :user="user" index="0"></UserPopover>
                </v-list>
              </v-menu>
            </div>
            <div v-else>
              <!--<span v-html="event.savedByIds.length"></span> <span>people participating</span>-->
            </div>

          </v-col>
        </v-row>


      </v-col>
    </v-row>
    <v-row class="pa-0 px-10 pb-10 justify-end">

      <div style="flex-wrap: wrap;
    gap: 10px;" class="pa-0 mt-3 col-12 col-md-10 float-right  d-flex justify-space-between">

        <div class="d-flex flex-wrap" style="gap:10px">
          <v-chip class="pa-5" color="secondary">
          <v-icon  color="primary" style="font-size: 1.2rem">mdi-map-marker</v-icon>
          <span class="text--primary" v-html="event.venue?.name"></span></v-chip>

        <v-chip class="pa-5 text--primary" color="secondary" v-if="event.conference && event.conferenceType" v-text="event.conferenceType"></v-chip>
          <span class="mr-5 hidden-sm-and-down" v-if="showBuyTicket">
              <AddToCartButton v-if="ticketLinkOrID==='id'" :ticket-id="event.ticketUid"/>
              <v-chip color="accent" class="pa-5" target="_blank" :href="event.ticketLink" v-else>Ticket</v-chip>
            </span>
        </div>
        <v-menu left width="200px">
          <template #activator="{attrs,on}">
        <v-chip v-on="on" v-bind="attrs" class="pa-5" color="secondary" style="font-size:0.85rem">
          <span class="text--primary" v-text="start"></span><span class="text--primary"> - {{end}}</span></v-chip>
          </template>
          <v-card>
            <v-card-text class="">
              <p class="d-flex justify-space-between text-left"><b class="mr-2">Begins: </b>{{start}}</p>
              <p class="d-flex justify-space-between mb-0 text-left"><b class="mr-2">Ends: </b>{{endDetail}}</p>
            </v-card-text>
          </v-card>
        </v-menu>
        <span class="mr-5 hidden-md-and-up" v-if="showBuyTicket">
              <AddToCartButton v-if="ticketLinkOrID==='id'" :ticket-id="event.ticketUid"/>
              <v-chip color="accent" class="custom-border-radius" target="_blank" :href="event.ticketLink" v-else>Ticket</v-chip>
            </span>
      </div>
    </v-row>

  </v-sheet>


</template>

<script>
import ApiService from "@/services/api.service";
import UserPopover from "@/views/Lobby/UserPopover";
import moment from "moment";
import Notification from "@/models/notification";
import Event from "@/models/event";
import AddToCartButton from "@/components/cart/AddToCartButton";

export default {
  name: "EventItem",
  components: {AddToCartButton, UserPopover},
  props: {
    artists: Array,
    event: Event,
    anonym:Boolean,
  },
  data() {
    return {
      showAll: false,
      backgroundOpacity: 0.12
    }
  },
  computed: {
    showBuyTicket(){
     if(this.event.conference) return false;
     if(this.event.ticketUid || this.event.ticketLink) return true;
     return false;
    },
    ticketLinkOrID(){
      if(this.showBuyTicket){
        if(this.event.ticketUid) return 'id'
        else return 'link'
      }else return null;
    },
    eventLink(){
      if(this.anonym) return "/u/event/"+this.event.id;
      else return "/event/"+this.event.id
    },
    artistsComputed() {
      let artists = this.artists;
      if(!artists) return;
      if (artists.length >= 3) {
        return artists.slice(0, 2);
      } else return artists;
    },
    start() {
      moment.locale('en');
      return moment(this.event.dateTime).format('LLLL');
    },
    end(){
      moment.locale('en');
      return moment(this.event.end).format("LT");
    },
    endDetail(){
      moment.locale('en');
      return moment(this.event.end).format("LLLL");
    },
    eventDay() {
      moment.locale("en");
      return moment(this.event.dateTime).format('dddd');
    },
    currentUser: {
      get() {
        return this.$store.state.auth.user;

      },
      set(data) {
        this.$store.state.auth.user = data;
      }
    }
  },
  watch: {
    '$vuetify.theme.dark'() {
      if (this.$vuetify.theme.dark) this.backgroundOpacity = 0.02;
      else this.backgroundOpacity = 0.12;
    }
  },
  methods: {
    addToFavourites(id) {
      if (!this.currentUser.favourites) this.currentUser.favourites = new Array();
      if (!this.currentUser.favourites?.includes(id)) {
        this.currentUser.favourites.push(id);
        this.updateUser();

        ApiService.get("event/favourites/add/" + this.event.id);
        this.$emit("addToFavourites", this.currentUser.id);
        //this.event.savedByIds.push(this.currentUser);

      }
    },
    removeFavourite(id) {
      if (this.currentUser.favourites?.includes(id)) {
        this.currentUser.favourites.splice(this.currentUser.favourites.indexOf(id), 1);
        this.updateUser();
        ApiService.get("event/favourites/remove/" + this.event.id);
        this.$emit("removeFromFavourites", this.currentUser.id);
        //this.event.savedByIds.splice(this.event.savedByIds.indexOf(x=> x.id=this.currentUser.is),1);
      }
    },
    loginToSaveFavourites() {
      this.$store.dispatch("notificationsStore/add", new Notification("warning", "Login to save Favourites.", false, null, "/login", "Login now"))
    },
    updateUser() {
      let self = this;
      ApiService.post("user/update", this.currentUser).then(response => {
        console.log(this.currentUser)
        let user = JSON.parse(localStorage.getItem("user"));
        let merged = {...user, ...response.data};
        console.log(merged)
        this.$store.dispatch("auth/updateLocalUser", merged);
        self.$store.dispatch("loading/success");
      });
    },
  }
}
</script>

<style scoped>
.event-box-overlay {
  background-position: 0, 50%;
  background-image: url("../../assets/wellen-2.webp");
  background-size: cover;
  opacity: 0.12;
}

.event-box {
  border-style: solid;
  border-top-style: solid;
  border-right-style: solid;
  border-bottom-style: solid;
  border-left-style: solid;
  border-width: 2px 2px 2px 2px;
  border-top-width: 2px;
  border-right-width: 2px;
  border-bottom-width: 2px;
  border-left-width: 2px;
  border-color: #BCBCBC;
  border-top-color: rgb(188, 188, 188);
  border-right-color: rgb(188, 188, 188);
  border-bottom-color: rgb(188, 188, 188);
  border-left-color: rgb(188, 188, 188);
}
</style>