<template>
<v-dialog max-width="500px" v-model="show">
  <template #activator="{attrs,on}">
    <v-btn text v-bind="attrs" v-on="on">My Orders</v-btn>
  </template>
  <v-card v-if="orders">
    <v-card-title><h2>My Orders</h2> <v-btn plain small :loading="loading" @click="updateOrders">Update</v-btn> <v-spacer/> <v-btn icon @click="show=false"><v-icon>mdi-close</v-icon></v-btn></v-card-title>
    <v-card-text>
      <h3 class="my-5" v-if="orders.length<=0 && !loading">You have no Orders</h3>
      <OrderList :orders="orders"/>
    </v-card-text>
  </v-card>
</v-dialog>
</template>

<script>
import OrderList from "@/components/order/OrderList";
export default {
  name: "MyOrders",
  components: {OrderList},
  data(){
    return{
      show:false,
      loading:false,
    }
  },
  computed:{
    orders:{
      get(){
        return this.$store.state.cart.orders;

      },set(value){
        this.$store.state.cart.orders=value;
      }
      }
  },
  created() {
    this.getMyOrders();
  },
  methods:{
    async updateOrders(){
      this.loading=true;
      this.orders = await this.$store.dispatch("cart/updateOrders");
      this.loading=false;
    },
    async getMyOrders() {
      this.loading=true;
      this.orders = await this.$store.dispatch("cart/getOrders");
      this.loading=false;
    }
  }
}
</script>

<style scoped>

</style>