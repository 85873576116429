<template>
  <v-list-item>
    <v-list-item-avatar v-if="kitItem.type.includes('image')" tile size="80px"><v-img :src="'data:'+kitItem.type+';base64,'+kitItem.file"></v-img></v-list-item-avatar>
    <v-list-item-icon v-else><v-icon>mdi-document</v-icon></v-list-item-icon>
    <v-list-item-title><h3 v-text="kitItem.name"></h3></v-list-item-title>
    <v-list-item-action>
      <v-btn @click="download" text icon><v-icon>mdi-download</v-icon></v-btn>
      <v-btn @click="deleteItem" text color="red" icon><v-icon>mdi-delete</v-icon></v-btn>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
import {deletePressKitItem, downloadFile} from "@/services/press.service";

export default {
  name: "PressKitItem",
  props:{
    kitItem:Object,
    userId:String
  },
  data(){
    return{

    }
  },
  computed:{
    currentUser(){
      return this.$store.state.auth.user;
    }
  },
  methods:{
    async deleteItem() {
      if(confirm("Do you really want to remove "+this.kitItem.name+"?")){
        let data = await deletePressKitItem(this.kitItem.id);
        if(data){
          this.$emit("delete",this.kitItem.id);
        }
      }else return;

    },
    download(){
        downloadFile(this.userId,this.kitItem.id)
    }
  }
}
</script>

<style scoped>

</style>