<template>
  <v-list-item two-line class="offwhite mx-5 my-2">
    <v-list-item-avatar>
      <v-img :src="'data:image/png;base64,'+artist.profilePicture" alt="Nulla"/>
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title class="font-weight-bold" style="font-size: 1.3rem;" v-html="artist.name">

      </v-list-item-title>
      <v-list-item-subtitle>
    <span v-for="(profession,index) of artist.professions" :key="index">
      <span class="mx-1" v-text="profession.company?.name"></span>
      <span v-if="index < artist.professions.length-1">|</span>
    </span>

      </v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-action>
      <v-btn v-if="!eventMember" @click="addArtist" icon><v-icon>mdi-plus</v-icon></v-btn>
      <v-btn v-else icon @click="removeArtist"><v-icon>mdi-minus</v-icon></v-btn>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
import {addArtist, removeArtist} from "@/services/event.service";

export default {
  name: "ArtistWithNameAndImage",
  props:{
    eventMember:Boolean,
    artist:Object,
    eventId:String
  },
  methods:{
    async addArtist() {
      let success = await addArtist(this.eventId, this.artist.id);
      if(success){
        this.$emit("addArtist",this.artist);
      }
    },
    async removeArtist() {
      let success = await removeArtist(this.eventId, this.artist.id);
      if (success) {
        this.$emit("removeArtist", this.artist);
      }
    }
  }
}
</script>

<style scoped>

</style>