<template>
  <div v-if="event">
  <div style="min-height:600px; position: relative; background: linear-gradient(255deg, rgba(131,52,146,1) 0%, rgba(255,255,255,0) 50%), black;" class="d-flex align-center py-0 py-md-15">
    <v-container style="max-width: 1200px;position: relative" class="d-flex flex-wrap justify-center align-start">
      <h1 style="width:100%;color: #FFFFFF24;
    font-family: 'Neue Singular H', Sans-serif;
    font-size: 2rem;
    font-weight: 600;
    letter-spacing: 10px;
    -webkit-text-stroke-width: 1px;
    stroke-width: 1px;
    -webkit-text-stroke-color: #FFFFFF;
    stroke: #FFFFFF;" class="text-center text-md-h2 text-uppercase">{{event.event.title}}</h1>
      <v-btn v-if="anonym" style="position: fixed;z-index:99; left:25px;top:85px;" @click="$router.back()"><v-icon>mdi-arrow-left</v-icon> Back</v-btn>
    </v-container>
  </div>
  <v-container style="position:relative;z-index:1;max-width: 800px" class="pb-5">
    <v-sheet elevation="1" style="margin-top:-50px" color="white" class="mx-auto pa-10 custom-border-radius" width="900px" max-width="90%">
      <v-row>
        <v-col class="pb-0 d-flex justify-center flex-wrap" cols="12">
          <span style="color:var(--v-accent-base);font-size:1.2rem" class="text-uppercase text-truncate font-weight-thin mr-1" v-for="(genre,index) of event.event.genres" :key="genre.id">{{genre.name}}<span v-if="index<event.event.genres.length-1">,</span> </span>

        </v-col>
      </v-row>
      <v-row>
        <v-col v-if="event.artists && event.artists.length>0" cols="12" style="font-size: 1.5rem;line-height: 25px;" class="d-flex flex-wrap justify-center font-weight-bold">
          <span>w\</span><span class="ml-1 hvr-grow" style="cursor: pointer" v-for="(artist,index) of event.artists" @click="$router.push('/artist/'+artist.id)" :key="artist.id">{{artist.name}}<span v-if="index<event.artists.length-1">,</span></span>
        </v-col>
      </v-row>
      <v-row class="d-flex justify-start flex-wrap">
        <v-col>
          <v-chip>
            <v-icon>mdi-map-marker</v-icon><span v-if="event.event.venue" v-text="event.event.venue.name"></span><span v-else>TBA</span>
          </v-chip>
        </v-col>
        <v-col class="d-flex">
          <v-chip>
            <v-icon>mdi-calendar</v-icon><span v-text="date"></span>
          </v-chip>
        </v-col>
        <v-col  class="d-flex">
          <v-chip>
            <v-icon>mdi-clock</v-icon><span v-text="time"></span>
          </v-chip>
        </v-col>
      </v-row>
    </v-sheet>
    <v-spacer style="height:100px"/>
    <div v-if="event.event.description"><h4 style="color:var(--v-accent-base)">Details</h4>
    <h2  class="mb-5 " v-text="event.event.description"></h2>
    </div>
    <div>
      <v-btn x-large v-if="event.event.instagram" :href="event.event.instagram" class="ml-2" large color="primary"><v-icon>mdi-instagram</v-icon></v-btn>
      <v-btn x-large v-if="event.event.facebook" :href="event.event.facebook" class="ml-2" large color="primary"><v-icon>mdi-facebook</v-icon></v-btn>
    </div>
  </v-container>
    <v-spacer style="height:100px"/>
  <v-container style="max-width: 800px">
    <div>


      <v-row style="padding-bottom: 200px" id="ticket" v-if="event.event.ticketUid">
        <v-col cols="12">
          <h2 style="font-size:2rem;" class="mb-5">Ticket</h2>
          <SingleTicketID :uid="event.event.ticketUid"/>
        </v-col>
      </v-row>
    <h2 v-if="event.artists && event.artists.length>0" style="font-size: 2rem;">SPEAKERS & ARTISTS ON THIS:</h2>
      <ArtistListPublic :anonym="anonym" :artists="event.artists"/>
    </div>


  </v-container>

</div>
</template>

<script>
import {getEventWithArtistsById} from "@/services/event.service";
import ArtistListPublic from "@/components/artist/ArtistListPublic";
import SingleTicketID from "@/components/event/SingleTicketID";

export default {
  name: "SingleEventSite",
  components: {SingleTicketID, ArtistListPublic},
  props:{
    eventId:String
  },
  computed:{
    anonym(){
      return this.$route.meta.hiddenHeaderAndFooter;
    },
    date(){
      let moment= require("moment");
      return moment(this.event.event.dateTime).format("dddd MM[/]DD[/]YY");
    },
    time(){
      let moment= require("moment");
      return moment(this.event.event.dateTime).format("LT");
    }
  },
  data(){
    return{
      event:null
    }
  },
  created() {
    if(this.eventId) this.getEvent();
  },
  methods:{
    getEvent: async function () {
      this.event = await getEventWithArtistsById(this.eventId)
    }
  }
}
</script>

<style scoped>
h1{
  font-size: 2rem;
}
@media only screen and (max-width: 600px) {
  h1{
    font-size: 1.3rem;
  }
}
</style>