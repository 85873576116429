import { render, staticRenderFns } from "./ChatPartners.vue?vue&type=template&id=7136ea50&scoped=true"
import script from "./ChatPartners.vue?vue&type=script&lang=js"
export * from "./ChatPartners.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7136ea50",
  null
  
)

export default component.exports