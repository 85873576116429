<template>
  <div class="game-container" style="width:100%;height:100%;">
    <div
        v-if="current"
        class="fixed fixed--center"
        style="z-index: 3"
        :class="{ 'transition': isVisible }">
      <Vue2InteractDraggable
          v-if="isVisible"
          :interact-out-of-sight-x-coordinate="500"
          :interact-max-rotation="15"
          :interact-x-threshold="10"
          :interact-y-threshold="10"
          interact-lock-swipe-down
          :interact-event-bus-events="interactEventBus"
          @draggedRight.once="emitAndNext('match')"
          @draggedLeft.once="emitAndNext('reject')"
          @draggedUp.once="emitAndNext('skip')"
          class="rounded-borders game-card game-card--one">
        <div style="height: 100%">
          <img
              :src="current.profilePic?'data:image/png;base64,'+current.profilePic.file :'//ssl.gstatic.com/accounts/ui/avatar_2x.png'"
              class="rounded-borders"/>
          <v-sheet dark>
          <v-layout class="game-text d-flex justify-space-between">
            <v-flex xs8 class="d-flex flex-wrap">
              <h3 style="width:100%">{{current.firstName+' '+current.lastName}} </h3>
              <small><span>{{current.labelRole}} @{{current.companyName}}</span></small>
            </v-flex>
            <v-flex xs4 class="justify-center">
              <v-btn class="align-self-center" icon><v-icon>mdi-information-outline</v-icon></v-btn>
            </v-flex>

          </v-layout>
          </v-sheet>
        </div>
      </Vue2InteractDraggable>
    </div>
    <div
        v-if="next"
        class="rounded-borders game-card game-card--two fixed fixed--center"
        style="z-index: 2">
      <div style="height: 100%">
        <img
            :src="next.profilePic?'data:image/png;base64,'+next.profilePic.file :'//ssl.gstatic.com/accounts/ui/avatar_2x.png'"

        class="rounded-borders"/>
        <v-sheet dark>
        <v-layout class="game-text d-flex justify-space-between">
          <v-flex xs8 class="d-flex flex-wrap">
            <h3 style="width:100%">{{next.name}} </h3>
          <small><span>{{next.labelRole}} @{{next.companyName}}</span></small>
          </v-flex>
          <v-flex xs4 class="d-flex justify-center">
            <v-btn class="align-self-center" icon><v-icon>mdi-information-outline</v-icon></v-btn>
          </v-flex>

        </v-layout>
        </v-sheet>
      </div>
    </div>
    <div
        v-if="index + 2 < cards.length"
        class="rounded-borders game-card game-card--three fixed fixed--center"
        style="z-index: 1">
      <div style="height: 100%">
      </div>
    </div>
    <div class="footer fixed">

      <v-btn fab @click="rejectBtn">
        <v-icon>mdi-close</v-icon>
      </v-btn>

      <v-btn fab @click="skipBtn">
        <v-icon>mdi-call-missed</v-icon>
      </v-btn>
      <v-btn color="accent" fab @click="matchBtn">
        <v-icon >mdi-star</v-icon>
      </v-btn>
    </div>
  </div>
</template>
<script>
import { Vue2InteractDraggable,InteractEventBus } from 'vue2-interact'
import ApiService from "@/services/api.service";
const EVENTS = {
  MATCH: 'match',
  SKIP: 'skip',
  REJECT: 'reject'
}

export default {
  name: 'UserGame',
  components: { Vue2InteractDraggable },
  created() {
    this.getUsers();
  },
  data() {
    return {
      isVisible: true,
      index: 0,
      cards: [

      ],
      interactEventBus: {
        draggedRight: EVENTS.MATCH,
        draggedLeft: EVENTS.REJECT,
        draggedUp: EVENTS.SKIP
      },
    }
  },
  computed: {
    current() {
      return this.cards[this.index]
    },
    next() {
      return this.cards[this.index + 1]
    }
  },
  methods: {
    getUsers(){
      let self=this;
      ApiService.get("user/getAll").then(response => {
        self.cards=response.data;

      })
    },
    matchBtn() {
      InteractEventBus.$emit(EVENTS.MATCH)
    },
    rejectBtn() {
      InteractEventBus.$emit(EVENTS.REJECT)
    },
    skipBtn() {
      InteractEventBus.$emit(EVENTS.SKIP)
    },
    match() {
      console.log("Match");
    },
    reject() {
      console.log("Reject");
    },
    skip() {
      console.log("Skip");
    },
    emitAndNext(event) {
      this.$emit(event, this.index);
      if(event==='match') this.match();
      else if(event==='reject') this.reject();
      else this.skip();
      setTimeout(() => this.isVisible = false, 200)
      setTimeout(() => {
        this.index++
        this.isVisible = true
      }, 200)
    }
  }
}
</script>

<style lang="scss" scoped>
.footer {
  width: 77vw;
  bottom: 60px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  padding-bottom: 30px;
  justify-content: space-around;
  align-items: center;
}
.game-container {
  width: 100%;
  height: 100vh;
}




.flex {
  display: flex;
  &--center {
    align-items: center;
    justify-content: center;
  }
}

.fixed {
  position: fixed;
  &--center {
    left: 50%;
    top: 40%;
    transform: translate(-50%, -50%);
  }
}
.rounded-borders {
  border-radius: 12px;
}
.game-card {
  width: 80vw;
  height: 60vh;
  color: white;
  img {
    object-fit: cover;
    display: block;
    width: 100%;
    height: 100%;
  }
  &--one {
    box-shadow: 0 1px 3px rgba(0,0,0,.2), 0 1px 1px rgba(0,0,0,.14), 0 2px 1px -1px rgba(0,0,0,.12);
  }
  &--two {
    transform: translate(-48%, -48%);
    box-shadow: 0 6px 6px -3px rgba(0,0,0,.2), 0 10px 14px 1px rgba(0,0,0,.14), 0 4px 18px 3px rgba(0,0,0,.12);
  }
  &--three {
    background: rgba(black, .8);
    transform: translate(-46%, -46%);
    box-shadow: 0 10px 13px -6px rgba(0,0,0,.2), 0 20px 31px 3px rgba(0,0,0,.14), 0 8px 38px 7px rgba(0,0,0,.12);
  }
  .game-text {
    position: absolute;
    bottom: 0;
    padding:1em 2em;
    width: 100%;
    span {
      font-weight: normal;
    }
  }
}

.transition {
  animation: appear 200ms ease-in;
}

@keyframes appear {
  from {
    transform: translate(-48%, -48%);
  }
  to {
    transform: translate(-50%, -50%);
  }
}
</style>