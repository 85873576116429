<template>
  <v-autocomplete
    v-model="selectedUsers"
    :items="items"
    hint="Hit enter to search"
    :loading="isLoading"
    :search-input.sync="search"
    @keydown.enter="searchAgain"

    append-outer-icon="mdi-content-save"
    @click:append-outer="save"
    item-text="email"
    multiple
    small-chips
    item-value="id"
    label="All users that can edit this Event"
    persistent-hint
    placeholder="Start typing and hit Enter to Search."
    prepend-icon="mdi-database-search"
>
    <template #no-data>
      <div class="pa-5">
      <div v-if="nothingFound">No user with that email found. </div>
      <div v-else>Type in the email of the User and hit Enter.</div>
        <v-btn @click="searchAgain" small>Search</v-btn>
      </div>
    </template>

  </v-autocomplete>
</template>

<script>
import {getUsersByIds, searchForUser} from "@/services/user.service";

export default {
  name: "ShareToUser",
  props:{
    editableBy:Array,
  },
  data(){
    return{
      selectedUsers:[],
      nothingFound:false,
      items:[],
      search:null,
      isLoading: false,
      count:0
    }
  },
  async created() {
    if (this.editableBy && this.editableBy.length > 0) {
      let users = await getUsersByIds(this.editableBy);
      this.items.push(...users);
      this.selectedUsers = JSON.parse(JSON.stringify(this.editableBy));

    }
  },
  methods:{
    async save() {
      this.$emit("updateShareList", this.selectedUsers);
    },
    searchAgain(){
      let val = this.search;

      //Search String min 3 chars
      if(val.length<3) return
      // Items have already been requested
      if (this.isLoading) return

      this.isLoading = true

      let self = this;
      searchForUser(val)
          .then(res => {
            self.items.push(...res.data);

            self.count = self.items.length;
            if(res.data.length<=0) self.nothingFound= true;
            else self.nothingFound = false;
          })
          .catch(err => {
            console.log(err)
          })
          .finally(() => (self.isLoading = false))
    },
  },
  watch: {
    search(val) {

      // Items have already been loaded
      if (this.items.length > 0) return


      //Search String min 3 chars
      if(val.length<3) return
      // Items have already been requested
      if (this.isLoading) return

      this.isLoading = true

      // Lazily load input items
      let self = this;
      searchForUser(val)
          .then(res => {

            self.items.push(...res.data);

            self.count = self.items.length;
          })
          .catch(err => {
            console.log(err)
          })
          .finally(() => (self.isLoading = false))
    },
  }
}
</script>

<style scoped>

</style>