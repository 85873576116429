<template>
  <v-sheet style="position: relative;max-width: 1200px" color="transparent" class="pa-7 mx-auto">
  <ContentLockedMessage :role="{id:0,name:'ROLE_TICKET_24'}"/>
   <div class="d-flex flex-wrap flex-md-nowrap align-center">
    <v-chip-group  multiple v-model="filterByTags">
     <v-chip v-for="tag of tags" :value="tag.id" :key="tag.id">{{tag.name}}</v-chip>
   </v-chip-group>
     <v-text-field @keypress.enter="searchLobby" hide-details hint="You can also filter by tags by clicking below." v-model="searchQuery" prepend-inner-icon="mdi-magnify" placeholder="Search by Name, Profession & Team" outlined dense></v-text-field>

   </div>
    <div v-if="filterByTags.length>0 && tags.length>0">
      <small>Filtered by: </small><v-chip small outlined close v-for="tagItem of filterByTags" @click:close="removeFromFilter(tagItem)" :key="tagItem"><span v-html="displayTag(tagItem)"></span></v-chip>
    </div>
    <div class="d-flex justify-space-between align-center">

      <div style="font-size: 0.7rem" class="text-truncate"><span v-if="searchQuery.length<=0 && filterByTags.length<=0">{{$store.state.network.lobby.totalNumberOfUsers}}  Users</span> <span v-else>{{filteredUsers.length}} Users found</span></div>
      <v-select class="px-0 shrink transparent-input" style="width:200px;font-size:0.7rem" dense hide-details solo v-model="$store.state.network.recommendations.sortBy" :items="$store.state.network.recommendations.canSortBy" flat>

        <template v-slot:prepend-inner>
          <v-icon small>mdi-swap-vertical</v-icon>
        </template>
        <template v-slot:selection="{item}">
          <span class="text-truncate">Sorted by: {{item}}</span>
        </template>
      </v-select>
    </div>
    <div v-if="loading" class="rounded wrapper">
    <v-skeleton-loader v-for="index in 20" :key="index">

    </v-skeleton-loader>
    </div>
       <div v-else class="rounded wrapper">

      <v-hover v-for="(user,index) in filteredUsers" :key="user.id"
          v-slot="{ hover }"

      >
        <UserSheetWall @addTagToFilter="addTagToFilter" :index=index :size="1.5" :hover="hover" :user="user"/>
      </v-hover>
         </div>

    <div v-if="loadingMore" class="ma-5 d-flex justify-center">
      <v-progress-circular color="accent" class="mx-auto" size="25" indeterminate/>
    </div>
  </v-sheet>
</template>

<script>
import ApiService from "@/services/api.service";
import UserSheetWall from "@/views/Lobby/UserSheetWall";
import ContentLockedMessage from "@/views/ViewPermission/ContentLockedMessage";

export default {
  name: "LobbyNew",
  components: {ContentLockedMessage, UserSheetWall},
  props:{
    filter:Number,
  },
  data(){
    return{
      loadingMore:false,
      loading:false,
      showFilter:false,
      sortedBy:null,
      users:[],
      filterByTags:[],
      filterByName:"",
      filterByCompanies:[],
      filterByRoles:[],
      searchQuery:"",
      tags:[]
    }
  },
  watch:{
    filter(){
      this.addTagToFilter(Number(this.filter));
    },
    searchQuery(){
      this.fetchItems();
    },
    filterByTags(){
      this.fetchItems();
    }
  },

  created() {
    if(this.filter){
      this.addTagToFilter(Number(this.filter));
    }
    this.getUsers();
    this.getAllTags();

  },
  computed:{
    companies(){
      return Array.from(new Set(this.users.map((user) => {

        return user.companyName;

      })));
    },roles(){
      return Array.from(new Set(this.users.map((user) => {

        return user.labelRole;

      })));
    },
    filteredUsers(){
      let users= this.$store.state.network.lobby.users;

      if(this.$store.state.network.recommendations.sortBy==='Recommended') {
        return users.sort(function(a, b) {
          return (a.algorithmScore < b.algorithmScore) ? 1 : (a.algorithmScore > b.algorithmScore) ? -1 : 0;
        });
      }
      else if(this.$store.state.network.recommendations.sortBy==='A to Z') {
        return users.sort(function(a, b) {
          var textA = a.name.toUpperCase();
          var textB = b.name.toUpperCase();
          return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        });
      }
      else if(this.$store.state.network.recommendations.sortBy==='Z to A') {
        return users.sort(function(a, b) {
          var textA = a.name.toUpperCase();
          var textB = b.name.toUpperCase();
          return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        }).reverse();
      }


      return users;

    },
    displayedUsers(){
      return this.$store.state.network.lobby.users;
    },
    currentUser(){
      return this.$store.state.auth.user;
    },

  },
  mounted() {
    // Add a scroll event listener when the component is mounted
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    // Remove the scroll event listener when the component is destroyed
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods:{
    isScrolledToBottom() {
      // Calculate the scroll position
      const scrollTop =
          window.pageYOffset || document.documentElement.scrollTop;
      const windowHeight =
          window.innerHeight || document.documentElement.clientHeight;
      const bodyHeight = document.body.scrollHeight;
      console.log(scrollTop + windowHeight >= bodyHeight);

      // Check if the scroll position is at the bottom
      return scrollTop + windowHeight >= bodyHeight-1;
    },
    async onScrollToBottom() {
      console.log("Scrolled to the bottom!");
      if (this.loading || this.loadingMore || this.$store.state.network.lobby.page >= this.$store.state.network.lobby.totalPages) {
        return null;
      }
      this.loadingMore = true;
      await this.$store.dispatch("network/loadMoreLobby",{
        "search": this.searchQuery,
        "page": this.$store.state.network.lobby.page,
        "tagIds": this.filterByTags
      });
      this.loadingMore=false;
      // Add your code here to execute when scrolled to the bottom
    },
    handleScroll() {
      // Check if scrolled to the bottom and execute the function
      if (this.isScrolledToBottom()) {
        this.onScrollToBottom();
      }
    },
    async searchLobby() {
      console.log("Api Call")
      await this.$store.dispatch("network/search", {
        "search": this.searchQuery,
        "page": this.$store.state.network.lobby.page,
        "tagIds": this.filterByTags
      });
    },
    async fetchItems(){
      clearTimeout(this.debounceTimeout);
      this.debounceTimeout = setTimeout(async () => {
        try {
          this.loading =true;
          await this.searchLobby();
          this.loading=false;
        } catch (error) {
          console.error('Error fetching items:', error);
          this.loading=false;
        }
      }, 400); // Debounce time to prevent too many API calls
    },
    removeFromFilter(tagId){
     // console.log("Remove ",tagId)
    this.filterByTags.splice(this.filterByTags.indexOf(tagId), 1)
    },
    displayTag(tagItem){
      if(this.tags.length>0) {
        let result= this.tags.find(tag => {
          return tag.id===tagItem
        });
        if(result) return result.name
      } return null
    },
    getAllTags(){
      let self=this;
      ApiService.get("tags/get/all").then(response => {
        self.tags=response.data;
      })
    },
    addTagToFilter(id){
      if(this.filterByTags.includes(id)) this.filterByTags.splice(this.filterByTags.indexOf(id),1);
        else this.filterByTags.push(id)
    },
    async getUsers() {
      let self = this;
      self.loading = true;
      /** ApiService.get("user/get/lobby").then(response => {
        self.users=response.data;
       for(let user of self.users){
          user.bigDisplay=Math.random() < 0.1;
        }

        this.$store.dispatch("network/setUsers",self.users);
        self.loading=false;
      });
       **/
      await this.$store.dispatch("network/loadLobby");
      this.loading = false;
    },
    isPrime(n) {
  if (n<=1) return false;
  for (var i = 2; i <= n-1; i++)
    if (n % i == 0) return false;
  return true;
}
  }
}
</script>

<style scoped>

.wrapper {
  display:grid;
  grid-gap: 10px;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}
@media screen and (max-width: 600px) {
  .wrapper {
    display:grid;
    grid-gap: 10px;
    grid-template-columns: 1fr 1fr;
  }
}
.wrapper > div {
  width: 100%;
  height:100%;
  min-height: 250px;
  background-color: #E0E0E0;
  border: solid 1px white;
}

.background-name span{
  color:white!important;
}

</style>