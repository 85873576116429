<template>
<v-sheet  class="pb-2">
  <v-sheet ref="image" dark>
 <v-layout style="max-width: 1100px" class="mx-auto pt-5">
   <v-flex xs5 class="d-flex justify-center">
      <v-avatar style="transform:translateY(1.5em)" tile :style="{
      transform: 'translateY(1.5em)',
      width: $vuetify.breakpoint.smAndDown ? '150px' : '170px',
      height: $vuetify.breakpoint.smAndDown ? '150px' : '170px'
    }">
        <v-img v-if="user" class="rounded" :src="user.image?'data:image/png;base64,'+user.image :'//ssl.gstatic.com/accounts/ui/avatar_2x.png'"></v-img>

      </v-avatar>
   </v-flex>
   <v-flex xs7 class="">
     <v-sheet dark color="transparent" height="100%" class="pt-4" style="max-width:100%;position:relative">
       <v-skeleton-loader class="mb-2 mt-1" v-if="$store.state.loading.loadingState" type="heading"></v-skeleton-loader>
       <span  v-else   class="font-weight-bold" style="font-size:1.5rem">

       <span v-if="user.name" v-text="user.name"></span>
       <span v-else>Unnamed</span>
      </span><br v-if="!$store.state.loading.loadingState"/>
       <span v-if="$store.state.loading.loadingState">
         <v-skeleton-loader type="text" class="mt-3" width="200px"></v-skeleton-loader><br/>
       <v-skeleton-loader type="button" class="button-loader" style="border-radius: 10px 10px 0 0;position:absolute; left:0;bottom:0"></v-skeleton-loader>
    </span>
       <span v-else>
       <span v-if="professions[0] "><span v-html="professions[0].role"></span>@<span v-if="professions[0].company" v-html="professions[0].company.name"></span></span><br/>
       <div>
      <FollowButton class="my-2" :user-id="user.id"/>
         <v-btn min-width="170px" small color="accent" elevation="0" @click="openChat"><v-icon small class="mr-1">mdi-message</v-icon>Send message</v-btn>


    </div>
       </span>
     </v-sheet>
   </v-flex>
 </v-layout>
  </v-sheet>
  <v-sheet  color="whitevariant"  class=" ">
    <div style="max-width: 1100px;" class="pt-5 mx-auto">
    <div v-if="$store.state.loading.loadingState || user.bio">
      <h3 class="pt-5 px-5">Information</h3>
    <v-skeleton-loader style="min-height: 100px" class="py-5 px-5" v-if="$store.state.loading.loadingState" type="paragraph"></v-skeleton-loader>

    <p v-else style="min-height: 0" class="py-5 px-5">
      <span v-if="user.bio"  v-text="user.bio"></span>
      <span v-else>Nothing here...</span>
    </p>
    </div>
    <v-sheet v-if="$store.state.loading.loadingState" color="transparent" class="px-5 py-2 my-2 d-flex flex-nowrap justify-start">
      <v-skeleton-loader class="little-avatar-loader" type="avatar"></v-skeleton-loader>
      <v-skeleton-loader class="little-avatar-loader" type="avatar"></v-skeleton-loader>
      <v-skeleton-loader class="little-avatar-loader" type="avatar"></v-skeleton-loader>
      <v-skeleton-loader class="little-avatar-loader" type="avatar"></v-skeleton-loader>
      <v-skeleton-loader class="little-avatar-loader" type="avatar"></v-skeleton-loader>
      <v-skeleton-loader class="little-avatar-loader" type="avatar"></v-skeleton-loader>


    </v-sheet>
    <v-sheet v-if="user" color="transparent" class="pb-3 px-5 py-2 my-2 d-flex justify-start">


      <v-btn v-if="user.socials.spotify" :href="'https://open.spotify.com/artist/'+user.socials.spotify" target="_blank" icon color="primary">
        <v-icon>mdi-spotify</v-icon>
      </v-btn>
      <v-btn v-if="user.socials.soundcloud" :href="'https://soundcloud.com/'+user.socials.soundcloud" target="_blank" color="primary" icon>

        <v-icon>mdi-soundcloud</v-icon>
      </v-btn>
      <v-btn v-if="user.socials.instagram" :href="'https://www.instagram.com/'+user.socials.instagram" target="_blank" color="primary" icon>
        <v-icon>mdi-instagram</v-icon>
      </v-btn>
      <v-btn v-if="user.socials.youtube" :href="'https://www.youtube.com/'+user.socials.youtube" target="_blank" color="primary" icon>
        <v-icon>mdi-youtube</v-icon>
      </v-btn>
      <v-btn v-if="user.socials.linkedIn" :href="'https://www.linkedin.com/in/'+user.socials.linkedIn" target="_blank" color="primary" icon>
        <v-icon>mdi-linkedin</v-icon>
      </v-btn>
      <v-btn v-if="user.socials.twitter" :href="'https://twitter.com/'+user.socials.twitter" target="_blank" color="primary" icon>
        <v-icon>mdi-twitter</v-icon>
      </v-btn>

    </v-sheet>
    </div>
  </v-sheet>



  <div style="max-width: 1100px" class="mx-auto px-5 py-5 d-flex justify-start">
    <div class="d-flex justify-start flex-nowrap" v-if="$store.state.loading.loadingState">
      <v-skeleton-loader class="mr-1"  type="chip"></v-skeleton-loader>
      <v-skeleton-loader class="mr-1" type="chip"></v-skeleton-loader>
      <v-skeleton-loader class="mr-1" type="chip"></v-skeleton-loader>
    </div>
      <v-chip-group v-else column>
      <v-chip @click="$router.push('/lobby/'+tag.id)" v-for="tag of tags" :key="tag.id">
        {{tag.name}}
      </v-chip>
    </v-chip-group>
  </div>

  <v-sheet v-if="professions && professions.length>0"  style="max-width: 1100px" class="my-5 px-5 mx-auto">
    <h3>Professions</h3>
    <v-skeleton-loader v-if="$store.state.loading.loadingState" width="200px" type="list-item-two-line@3"></v-skeleton-loader>

    <v-list v-else-if="professions && professions.length>0">
      <v-list-item two-line v-for="profession of professions" :key="profession.id">
        <v-list-item-content v-if="profession.role && profession.company">
        <v-list-item-title>
          <span>{{profession.role}}</span>
        </v-list-item-title>
        <v-list-item-subtitle>
          <span>@<span v-if="profession.company">{{profession.company.name}}</span></span>
        </v-list-item-subtitle>
        </v-list-item-content>

      </v-list-item>
    </v-list>
  </v-sheet>

  <div style="max-width: 1100px" class="my-5 px-5 mx-auto" v-if="user && user.sameCompanyUsers.users.length>0">
  <h3 >People working with {{user.firstName}}</h3>
  <div class="rounded wrapper flex-wrap mt-5">
    <v-hover v-for="(user,index) in user.sameCompanyUsers.users" :key="user.id"
             v-slot="{ hover }"

    >
      <UserSheetWall size="1.3" :index=index :hover="hover" :user="user"/>
    </v-hover>
  </div>
      <div class="d-flex justify-center">
      <v-btn color="grey" small v-if="user.sameCompanyUsers.notLoadedUsers>0" class="my-2" @click="loadMoreWithSameCompany">Load more <v-icon>mdi-arrow-down</v-icon></v-btn>
      </div>
  </div>
  <div  style="max-width: 1100px" class="mt-10 px-5 mx-auto" v-if="user && user.sameRoleUsers.users.length>0">
    <h3 class="mt-5">Other <span v-for="(profession,index) of user.professions" :key="profession.id"><span v-html="profession.role"></span><span v-if="index<user.professions.length-1">/</span> </span></h3>
    <div class="rounded wrapper flex-wrap">
      <v-hover v-for="(user,index) in user.sameRoleUsers.users" :key="user.id"
               v-slot="{ hover }"

      >
        <UserSheetWall size="1.3" :index=index :hover="hover" :user="user"/>
      </v-hover>
    </div>
    <div class="d-flex justify-center">
      <v-btn color="grey" v-if="user.sameRoleUsers.notLoadedUsers>0" small class="my-2" @click="loadMoreWithSameLabelRole">Load more <v-icon>mdi-arrow-down</v-icon></v-btn>
    </div>
  </div>
  <div class="ma-5 mx-auto px-5" style="max-width: 1100px">
    <h3>Posts</h3>
    <FeedOfUser :user-id="id"/>
  </div>
</v-sheet>


</template>

<script>
import ApiService from "../../services/api.service";
import UserSheetWall from "@/views/Lobby/UserSheetWall";
import FeedOfUser from "@/components/feed/FeedOfUser";
import FollowButton from "@/components/user/FollowButton";

export default {
  name: "UserDetails",
  data(){
    return{
      user:null,
      tags:[],
      page:0,
      usersWithSameRole:{
        items:[],
        count:3
      },
      usersWithSameCompany:{
        items:[],
        count:3
      }
    }
  },
  beforeRouteUpdate() {
    this.getUser();
    //this.getProfessions(to.params.id);
    this.$emit("scrollTop");

  },
  computed:{
    professions(){
      return this.user.professions;
    },
  isArtist(){
    return this.currentUser.roles.includes("ROLE_ARTIST")
  },
    currentUser(){
      return this.$store.state.auth.user;
    }
  },
  components:{FollowButton, FeedOfUser, UserSheetWall},
  props:{
    id:String
  },
  async created() {
    await this.getUser();
  },
  methods:{
    openChat(){
      this.openNewChat();
      this.$router.push('/chat/open/'+this.user.id)
    },
    openNewChat(){
      if(this.currentUser.currentChatPartners && !this.currentUser.currentChatPartners.includes(this.user.id)){
        this.currentUser.currentChatPartners.push(this.user.id);

        this.updateUser();
      }

    },
    updateUser(){
      let self=this;
      ApiService.post("user/update",this.currentUser).then(response => {
        let user =JSON.parse(localStorage.getItem("user"));
        let merged = {...user, ...response.data};
        this.$store.dispatch("auth/updateLocalUser",merged);
        self.$store.dispatch("loading/success");
      });
    },
    loadMoreWithSameLabelRole(){
      let self=this;
      this.page++;
      ApiService.get("user/getAllByLabelRole/"+self.user.id+"/"+this.page).then(response => {
        self.user.sameRoleUsers.users.concat(response.data.users);
        self.user.sameRoleUsers.notLoadedUsers= response.data.notLoadedUsers;
      });
      },
    loadMoreWithSameCompany(){
      let self=this;
      this.page++
      ApiService.get("user/getAllWithSameCompany/"+self.user.id+"/"+this.page).then(response => {
        self.user.sameCompanyUsers.users.concat(response.data.users);
        self.user.sameCompanyUsers.notLoadedUsers= response.data.notLoadedUsers;
      });
    },
    getUser(){
      let self=this;
      self.$store.dispatch("loading/toggle",true);
      ApiService.get("user/get?id="+this.id+"&page="+this.page).then(response =>{
        self.user=response.data;
        self.$store.dispatch("loading/success");

      })
    }
  }
}
</script>
<style scoped>
h3{
  font-size: 1.6rem;
}
</style>
