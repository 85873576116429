<template>
    <v-list-group
        :value="expand"
    >
      <template v-slot:activator>
        <v-list-item-title style="">
          <h3 class="text-uppercase text-color-accent  mt-2" v-text="user.name"></h3>
        </v-list-item-title>
      </template>
      <div class="pa-5">
        <UploadPressFiles only-upload :user-id="user.id"/>
      </div>
        <DownloadPressOfUser :user="user"/>
      <PressKitItem v-for="pressKit of kit" :key="pressKit.id" :kit-item="pressKit"/>
  </v-list-group>

</template>
<script>
import PressKitItem from "@/components/press/PressKitItem";
import DownloadPressOfUser from "@/components/press/DownloadPressOfUser";
import UploadPressFiles from "@/components/press/UploadPressFiles";
export default {
  name: 'PressKitUser',
  components: {UploadPressFiles, DownloadPressOfUser, PressKitItem},
  props: {
    user:Object,
    kit:Array
  },
  //TODO On upload ass new kititems
  data(){
    return{
      expand:false
    }
  }
}
</script>
