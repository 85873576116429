import axios from "axios";
import store from '../store';
import {router} from "../router";
import Notification from "@/models/notification";
import ApiService from "@/services/api.service";

/**
 * Returns the Token Object after signIn Process.
 *
 * @param {code} x The Challange Code of Cognito.
 * @return {TokenObject} token and email.
 */
export async function getCognitoToken(code){
    //Start the loading Process
    store.dispatch("loading/startCognitoProcess");

    const body = `client_id=6fb837a28n4589dkr8r5c9n6vb&grant_type=authorization_code&code=`+code+'&redirect_uri='+process.env.VUE_APP_FRONTHOST+'/login';
    const url="https://bdme.auth.eu-central-1.amazoncognito.com/oauth2/token";
    store.state.loading.loginCognitoProcess.neededEmail=null;
    //Send the Challange Code to Cognito to get Back the JWT Token
    try {
        const response = await axios.post(
            url,
            body,
            {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    //'Authorization': 'Basic Basic N2R0b29jYWlxZTFiMTU1cXFmanFxb2ppdTI6MTl2NDBia2ZnYzQyMHZqZTBxdmwxcXBudW4yMmJwbTd0czNuamIxMzE0cjVmZG10ZHY2NA=='
                },
            });
        console.log("Repsonse")
        console.log(response.data);
        //TokenObject to send to Spring Auth
        let tokenObject = {
            token: null,
            extraEmail: null
        }
        tokenObject.token = response.data.id_token;
        //Check if ID_Token is present
        if (!response.data.id_token) {
            store.dispatch("notificationsStore/add", new Notification("error", "Login with Cognito did not work! Please try again", false))
            return null;
        }
        //Check if there is a request without an Email present
        store.dispatch("loading/checkNeedingExtraEmail", response.data.id_token);
        let object = await decodeJwtPayload(response.data.id_token);
        if (store.state.loading.loginCognitoProcess.isNeedingExtraEmail) {
            //TODO Check if EMail is already linked to account

            const checkEmailResponse = await ApiService.get("auth/cognito/getEmailByUsername?username=" + object['cognito:username']);
            if (checkEmailResponse.data && checkEmailResponse.data.length > 0) {
                tokenObject.extraEmail = checkEmailResponse.data;
                store.state.loading.loginCognitoProcess.isNeedingExtraEmail = false;
                return tokenObject;
            } else {
                console.log("No Email found");
                return tokenObject;
            }
        } else {
            if(object.email) tokenObject.extraEmail = object.email;
            else if(object['custom:provider_email']) tokenObject.extraEmail = object['custom:provider_email'];
            return tokenObject;
        }

    }
        catch(error) {
            console.log(error);
            router.push("/");
        }



    /**
    const data = new URLSearchParams({
        'grant_type': 'authorization_code',
        'client_id': '7dtoocaiqe1b155qqfjqqojiu2',
        'code': code,
        'redirect_uri': process.env.VUE_APP_HOST+'/login'
    });
    console.log(data)

    const response = await axios.post('https://bdme.auth.eu-central-1.amazoncognito.com/oauth2/token',
        body,
        {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': 'Basic N2R0b29jYWlxZTFiMTU1cXFmanFxb2ppdTI6MTl2NDBia2ZnYzQyMHZqZTBxdmwxcXBudW4yMmJwbTd0czNuamIxMzE0cjVmZG10ZHY2NA=='
            }
        }
    ).catch(error => {
        console.log("Error")
        console.log(error);
    });
    console.log(response);
    return response;
     */
}

export async function loginWithCognito(tokenObject) {
    store.dispatch('auth/loginCognito', tokenObject).then(
        () => {
            store.dispatch("loading/stopCognitoProcess");
            router.push('/profile');

        },
        () => {
            store.dispatch("loading/stopCognitoProcess");
            store.dispatch("notificationsStore/add", new Notification("error", "Login with Cognito failed.", false))
        }
    );
}

export async function decodeJwtPayload(token) {
    try {
        let base64Url = token.split('.')[1]; // Get the payload part of the token
        let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/'); // Replace URL-safe characters with base64 standard characters
        let jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
        return JSON.parse(jsonPayload);
    } catch (e) {
        console.error('Invalid token format', e);
        return null;
    }
}


