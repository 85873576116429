import { render, staticRenderFns } from "./AdminEventListItem.vue?vue&type=template&id=095df7d2&scoped=true"
import script from "./AdminEventListItem.vue?vue&type=script&lang=js"
export * from "./AdminEventListItem.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "095df7d2",
  null
  
)

export default component.exports