var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._m(0),_c('div',{staticClass:"mx-auto overflow-hidden",staticStyle:{"height":"300px"}},[_c('WebcamVideo',{on:{"upload":_vm.compressWebcamImage}})],1),_c('div',{staticClass:"d-flex justify-center"},[_c('v-btn',{staticClass:"mx-auto",attrs:{"loading":_vm.loading,"plain":"","text":""}},[_c('v-icon',{staticClass:"mr-1"},[_vm._v("mdi-image-multiple")]),_vm._v(" Use Camera Roll "),_c('input',{staticClass:"file-input",staticStyle:{"opacity":"0","width":"100%","height":"100%","position":"absolute","top":"0","left":"0"},attrs:{"type":"file","accept":"image/png, image/jpeg,image/bmp"},on:{"change":_vm.setImage}})],1)],1),_c('v-dialog',{staticStyle:{"max-height":"100%"},attrs:{"content-class":"overflow-hidden","fullscreen":"","max-width":"100%"},model:{value:(_vm.hasImage),callback:function ($$v) {_vm.hasImage=$$v},expression:"hasImage"}},[_c('v-card',[_c('v-card-actions',[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.hasImage=false}}},[_vm._v("Cancel")]),_c('v-spacer'),_c('v-btn',{attrs:{"text":"","loading":_vm.loading},on:{"click":_vm.cropCameraRoll}},[_vm._v("Upload")])],1),_c('cropper',{ref:"cropper",staticClass:"upload-example-cropper",staticStyle:{"width":"100%","height":"100%"},attrs:{"src":_vm.img,"stencil-props":{
		handlers: {},
		movable: false,
		scalable: false,
	},"stencil-size":{
		width: 280,
		height: 280
	},"image-restriction":"stencil","stencil-component":_vm.$options.components.Stencil}})],1)],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex justify-center text-center font-weight-bold",staticStyle:{"width":"100%"}},[_c('p',{staticStyle:{"width":"80%"}},[_vm._v("Take a photo to show everyone who u are")])])
}]

export { render, staticRenderFns }