export default class EditUser{
    userId;
     username;
     firstName;
     lastName;
     email;
    profilePicture;
    genre;
    tags;
    professions;
     bio;
     website;
     spotify;
     soundcloud;
     instagram;
     youtube;
     linkedIn;
     twitter;
}