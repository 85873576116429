<template>
<v-container>
  <h1>Bezahlung</h1>
  <v-col cols="12" class="col-7">
    <div v-if="bill.needsPayment">
      <h3>Zahlung</h3>
      <SelectPaymentMethod @updatePaymentMethod="updatePaymentMethod" :methods="bill.availablePaymentMethods"/>
    </div>
    <div>
      <h3>Versand</h3>
      <div>ETicket</div>
    </div>
  </v-col>
  <v-col cols="12" class="col-4">
    <v-sheet>
      <h3>Deine Bestellung</h3>
      <v-list-item v-for="product of bill.products" :key="product.uid">
        <v-list-item-title v-text="product.name"></v-list-item-title>
        <v-list-item-action-text><span v-text="product.totalPrice.amount"></span><span class="ml-1" v-text="product.totalPrice.currency"></span></v-list-item-action-text>
       </v-list-item>
      <v-list-item v-if="bill.discount">
        <v-list-item-subtitle>Discount</v-list-item-subtitle>
      <v-list-item-action-text ><span v-text="bill.discount.amount"></span><span class="ml-1" v-text="bill.discount.currency"></span></v-list-item-action-text>
      </v-list-item>
      <v-divider></v-divider>
      <v-list-item>
        <v-list-item-title>Gesamt</v-list-item-title>
        <v-list-item-action-text><span v-text="bill.grandTotal.amount"></span><span v-text="bill.grandTotal.currency"></span></v-list-item-action-text>
      </v-list-item>
    </v-sheet>

    <p>Wenn du auf "Bezahlen" klickst, stimmst du den Allgemeinen Geschäftsbedingungen dieses Kaufes zu.
      Paylogic</p>

    <a href="https://go.paylogic.com/de/gmbh/terms-and-conditions" target="_blank">Allgemeinen Nutzungsbedingungen</a><br/>

    <a href="https://go.paylogic.com/de/privacy-policy-seetickets" target="_blank">Datenschutzrichtlinie</a>
  </v-col>
</v-container>
</template>

<script>
import SelectPaymentMethod from "@/components/order/payment/SelectPaymentMethod";
export default {
  name: "OrderBill",
  props:{
    bill:Object,
  },
  methods:{
    updatePaymentMethod(value){
      this.$emit("updatePaymentMethod",value);
    }
  },
  components: {SelectPaymentMethod}
}
</script>

<style scoped>

</style>