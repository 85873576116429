<template>
  <div>
  <div style="width:100%" class=" d-flex justify-center text-center font-weight-bold"><p style="width:80%">As you're an artist, pick your genres</p></div>

  <div style="max-height: 300px;overflow-y:scroll">

    <GenrePicker :genre-to-edit="genresToEdit" @update="updateGenres"/>
  </div>
  </div>
</template>

<script>
import GenrePicker from "@/components/artist/GenrePicker";
import {updateGenres, updateUser} from "@/services/user.service";
export default {
  name: "WelcomeArtistGenre",
  components: {GenrePicker},
  computed:{
    currentUser(){
      return this.$store.state.auth.user;
    },
    genresToEdit(){
      return this.currentUser.genre.map(x => x.id);
    },
  },
  methods:{
    async updateGenres(genres) {
      let newGenres = await updateGenres(genres);
      this.currentUser.genre= newGenres;
      updateUser(this.currentUser)
    },
  }
}
</script>

<style scoped>

</style>