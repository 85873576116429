var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"width":"100%","height":"100%"}},[_c('div',{staticClass:"mx-auto custom-border-radius",staticStyle:{"width":"auto","height":"100%","position":"relative"}},[_c('v-img',{staticClass:"custom-border-radius",staticStyle:{"position":"absolute","top":"0","left":"0","max-width":"100%","max-height":"100%"},attrs:{"width":"100%","height":"100%","src":_vm.image ? 'data:image/png;base64,'+_vm.image :require('@/assets/Placeholder-Profile-819x1024.webp')}}),_c('v-chip',{staticStyle:{"position":"absolute","left":"5px","bottom":"5px"}},[_vm._v(" Click to edit "),_c('v-icon',{staticClass:"ml-1"},[_vm._v("mdi-camera")])],1),_c('input',{staticStyle:{"position":"absolute","top":"0","left":"0","width":"100%","height":"100%","z-index":"1","opacity":"0"},attrs:{"type":"file","placeholder":"File"},on:{"change":_vm.setImage}})],1),_c('v-dialog',{staticStyle:{"max-height":"100%"},attrs:{"content-class":"overflow-hidden","max-width":"100%"},model:{value:(_vm.hasImage),callback:function ($$v) {_vm.hasImage=$$v},expression:"hasImage"}},[_c('v-card',[_c('v-card-actions',[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.img=null}}},[_vm._v("Cancel")]),_c('v-spacer'),_c('v-btn',{attrs:{"text":"","loading":_vm.loading},on:{"click":_vm.crop}},[_vm._v("Save")])],1),_c('cropper',{ref:"cropper",staticClass:"upload-example-cropper",staticStyle:{"width":"100%","height":"100%","max-height":"90vh"},attrs:{"src":_vm.img,"stencil-props":{
		handlers: {},
		movable: false,
		scalable: false,
	},"stencil-size":{
		width: 560,
		height: 560
	},"image-restriction":"stencil","stencil-component":_vm.$options.components.Stencil,"defaultBoundaries":"fit"}}),_c('v-card-actions',{staticClass:"d-flex justify-space-between"},[_c('div',[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.zoom(2)}}},[_c('v-icon',[_vm._v("mdi-magnify-plus")])],1),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.zoom(0.5)}}},[_c('v-icon',[_vm._v("mdi-magnify-minus")])],1)],1),_c('div',[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.rotate(90)}}},[_c('v-icon',[_vm._v("mdi-rotate-right")])],1),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.rotate(-90)}}},[_c('v-icon',[_vm._v("mdi-rotate-left")])],1)],1),_c('div',[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.flip(true,false)}}},[_c('v-icon',[_vm._v("mdi-flip-horizontal")])],1),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.flip(false,true)}}},[_c('v-icon',[_vm._v("mdi-flip-vertical")])],1)],1)])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }