import { render, staticRenderFns } from "./ArtistListItemPublic.vue?vue&type=template&id=190cc4e4&scoped=true"
import script from "./ArtistListItemPublic.vue?vue&type=script&lang=js"
export * from "./ArtistListItemPublic.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "190cc4e4",
  null
  
)

export default component.exports