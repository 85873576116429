<template>
<v-card height="100%" class="">
  <v-img
      :src="user.profilePic?'data:image/png;base64,'+user.profilePic.file :require('../../assets/Placeholder-Profile-819x1024.webp')"
      class="background-image rounded" height="160px" width="100%"></v-img>
  <v-card-title class="text-truncate" style="font-size: 1rem;max-width: 100%" v-html="user.firstName+' '+user.lastName"></v-card-title>
  <v-card-subtitle><span v-html="user.labelRole"></span> <span>@</span><span v-html="user.companyName"></span></v-card-subtitle>
<v-card-actions>
  <v-spacer></v-spacer>
  <v-btn icon x-small @click="openChat"><v-icon>mdi-message</v-icon></v-btn>
  <v-btn icon x-small @click="$router.push('/user/'+user.id)"><v-icon>mdi-account</v-icon></v-btn>

</v-card-actions>
</v-card>
</template>

<script>
import {updateUser} from "@/services/user.service";

export default {
  name: "UserCard",
  props:{
    user:Object
  },
  computed:{
    currentUser(){
      return this.$store.state.auth.user;
    }
  },
  methods:{
    openChat(){
      this.openNewChat();
      this.$router.push('/chat/open/'+this.user.id)
    },
    openNewChat(){
      if(this.currentUser.currentChatPartners && !this.currentUser.currentChatPartners.includes(this.user.id)){
        this.currentUser.currentChatPartners.push(this.user.id);

        updateUser(this.currentUser)
      }

    },
  }
}
</script>

<style scoped>

</style>