export default function (input) {
    let words = require("naughty-words");



    if (words.de.some(v => input.includes(v))) {
        // There's at least one
        return false
    }
    else if(words.en.some(v => input.includes(v))) {
        // There's at least one
        return false
    }
    else return true;
}