import ApiService from "@/services/api.service";

export async function getMyTickets(){
    let response = await ApiService.get("ticket/my/all");
    if(response && response.data) return response.data;
}
export async function scanTicket(barcode){
    let response = await ApiService.get("ticket/scan/"+barcode);

     return response;
}
export async function getAllTickets(){
    let response = await ApiService.getUnauthorized("ticket/shop/all");
    return response.data;
}

export async function getBill(cart,vouchers){
    let order=[];
    for(let cartItem of cart){
        order.push({quantity:cartItem.amount,product:cartItem.product.uid});
    }
    let response = await ApiService.post("ticket/order/bill",{products:order,vouchers:vouchers});
    return response.data;
}

export async function getTicket(uid){
    let response = await ApiService.post("ticket/product/get/"+uid,{payload:"b7525ae446214b1c91c90f56dea939ae"});
    return response.data;
}