<template>
<div>
  <p class="text-center font-weight-bold">Now something about you<br/> personally</p>
  <v-text-field @change="updateUser(currentUser)" v-model="currentUser.website" solo dense flat outlined placeholder="Your Website"></v-text-field>
  <v-textarea @change="updateUser(currentUser)" solo dense v-model="currentUser.bio" flat outlined placeholder="Your Caption for your profile" hint="e.g. What is special about you?
 What do you want to tell the world?"></v-textarea>

</div>
</template>

<script>
import {updateUser} from "@/services/user.service";

export default {
  name: "PersonalInformation",
  computed:{
    currentUser(){
      return this.$store.state.auth.user;
    }
  },
  methods: {
    updateUser(user) {
      updateUser(user);
    },
  }
}
</script>

<style scoped>

</style>