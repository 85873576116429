<template>
<v-overlay class="custom-overlay" opacity="0.9" absolute z-index="20" v-model="notHasRole">
  <v-card dark max-width="500px" style="border:1.5px solid white">
    <v-card-title>No Access</v-card-title>
    <v-card-text>Sorry, you do not have access to this content. Link your valid ticket to your account to gain access.</v-card-text>
    <v-card-actions>
      <v-btn text @click="$router.back()">Cancel</v-btn><v-btn @click="$router.push('/ticket/redeem')">Connect now</v-btn>
    </v-card-actions>
  </v-card>
</v-overlay>
</template>

<script>
import Role from "@/models/role";

export default {
  name: "ContentLockedMessage",
  props:{
    role: Role
  },
  computed:{
    currentUser(){
      return this.$store.state.auth.user;
    },
    notHasRole(){
      let roles = this.currentUser.roles;
      if(roles.some( x => x.name === this.role.name) || roles.some(x => x.name === "ROLE_ARTIST") || roles.some(x => x.name === "ROLE_ADMIN") || roles.some(x => x.name === "ROLE_CONF_MANAGER") || roles.includes(this.role.name) || roles.includes("ROLE_ARTIST") || roles.includes("ROLE_CONF_MANAGER") || roles.includes("ROLE_ADMIN")) return false;
      else return true;
    }
  }
}
</script>

<style scoped>

</style>