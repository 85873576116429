import ApiService from "@/services/api.service";

export async function follow(userId) {
    let response = await ApiService.get("user/follow/" + userId);
    if(response?.data) return true;
    else return false;
}

export async function unfollow(userId) {
    let response = await ApiService.get("user/unfollow/" + userId);
    console.log("Response",response);
    if(response?.data) return true;
    else return false;
}