<template>
  <div @scroll="onScroll" ref="scrollContainer">
    <h3 v-if="!feed || (feed.length <= 0 && !loading)">Nothing posted.</h3>
    <FeedItem
        v-for="feedItem in feed"
        :key="feedItem.id"
        @updateItem="updateFeedItem"
        :content="feedItem"
    />
    <v-progress-circular v-if="loading" class="loading-indicator">Loading...</v-progress-circular>
  </div>
</template>

<script>
import FeedItem from "@/components/feed/FeedItem";
import { getFeedOfUser } from "@/services/lobby.service";

export default {
  name: "FeedOfUser",
  components: { FeedItem },
  props: {
    userId: String
  },
  data() {
    return {
      page: 0,
      feed: [],
      loading: false,
      allLoaded: false // To indicate if all items are loaded
    };
  },
  created() {
    this.getFeed();
  },
  methods: {
    updateFeedItem(item) {
      const index = this.feed.findIndex(x => x.id === item.id);
      if (index !== -1) {
        this.feed[index] = item;
      }
    },
    async getFeed() {
      if (this.loading || this.allLoaded) return;
      this.loading = true;
      const newFeed = await getFeedOfUser(this.userId, this.page);
      if (newFeed.length > 0) {
        this.feed = this.feed.concat(newFeed);
        this.page++;
      } else {
        this.allLoaded = true;
      }
      this.loading = false;
    },
    onScroll() {
      const scrollContainer = this.$refs.scrollContainer;
      if (scrollContainer.scrollTop + scrollContainer.clientHeight >= scrollContainer.scrollHeight) {
        console.log("loading")
        this.getFeed();
      }
    }
  }
};
</script>

<style scoped>
.loading-indicator {
  text-align: center;
  padding: 20px;
}
</style>
