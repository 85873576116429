<template>
<v-sheet color="secondary">
  <div>
  <v-list v-if="currentUser" ref="chat" class="chat chatHeight overflow-y-auto">
    <v-list-item class="d-flex justify-center flex-wrap">
      <div style="width:100%" class="mx-auto d-flex justify-center"><v-chip class="mx-auto my-5">Your chat with {{$store.state.chat.activeContact?.fullName}}</v-chip>
      </div>
      <v-btn small text plain @click="loadNextMessages">Load more messages</v-btn>
    </v-list-item>
    <div class="d-flex align-content-end align-end flex-wrap" style="min-height: 60vh">
    <v-list-item style="max-height: 500px" v-for="(message,index) of $store.state.chat.messages"  :key="message.id" :class="message.senderId == currentUser.id ? 'sent' : 'replies'">
      <v-spacer v-if="message.senderId == currentUser.id"/>
      <v-list-item-avatar width="5px" v-if="message.senderId != currentUser.id">
      <v-img  :src="$store.state.chat.activeContact.image?'data:image/png;base64,'+$store.state.chat.activeContact.image :require('../../assets/Placeholder-Profile-819x1024.webp')"
              :alt="$store.state.chat.activeContact.fullName"/>
    </v-list-item-avatar>
      <v-list-item-content :class="{ 'text-right':message.senderId == currentUser.id}">
        <div v-if="index<$store.state.chat.messages.length-1" :class="{ 'justify-end':message.senderId == currentUser.id}" class="d-flex "><v-sheet class="px-1 py-2 text-secondary" rounded color="primary" style="color:white;max-width: 100%;"><p style="word-break: break-all;">{{message.content}}</p></v-sheet></div>
        <div ref="last" v-else :class="{ 'justify-end':message.senderId == currentUser.id}" class="d-flex"><v-sheet class="px-1 py-2  text-secondary" rounded color="primary"   style="color:white;max-width: 100%;"><p style="word-break: break-all;">{{message.content}}</p></v-sheet></div>

        <small style="font-size: 0.7rem" class="font-weight-thin" v-html="messageTime(message.timestamp)"></small>
      </v-list-item-content>


    </v-list-item>
    </div>
    <div v-if="loadingMessages" class="mx-auto d-flex justify-center">
      <v-progress-circular size="15" indeterminate></v-progress-circular>
    </div>
  </v-list>
  <v-sheet max-width="100%"  class="rounded-t pb-0 bt-2 px-4">
  <v-layout>
    <v-flex class="mr-1 mb-1" xs12>
      <v-text-field counter :maxlength="250" :rules="[ rules.counter]" @click:prepend-inner="emojiDialog=!emojiDialog" prepend-inner-icon="mdi-emoticon" hide-details placeholder="Message" solo   v-model="newMessage" @keypress.enter="sendMessage(newMessage)">
        <template v-slot:append>
          <v-icon color="success" @click="sendMessage(newMessage)" v-if="$store.state.chat.connectionStatus"> mdi-send </v-icon>
          <v-tooltip v-else top>
            <template v-slot:activator="{ on, attrs }">
          <div v-on="on" v-bind="attrs">

            <v-progress-circular indeterminate size="16"></v-progress-circular><v-icon color="warning" > mdi-alert</v-icon>
          </div>
            </template>
            You are offline :( Reconnecting...
          </v-tooltip>
        </template>

      </v-text-field>
    </v-flex>

  </v-layout>
  </v-sheet>
  </div>
  <v-dialog width="325px" v-model="emojiDialog">
  <VEmojiPicker @select="insert" />
  </v-dialog>
</v-sheet>
</template>

<script>
import { VEmojiPicker } from 'v-emoji-picker';
import {findChatMessages, findChatMessagesPagable} from "@/services/socket.api";
import ApiService from "../../services/api.service";
import {loadContacts} from "@/services/chat.service";
import Notification from "@/models/notification";

export default {
  name: "ChatItem",
  props:{
    activeContact:null,
    id:null
  },
  components:{VEmojiPicker},
  data() {
    return {
      loadingMessages:false,
      emojiDialog:false,
    newMessages:[],
      newMessage:"",
      loadedMessagesPage:1,
      rules: {
        counter: value => value.length <= 250 || 'Max 250 characters',
      },
    }
  },
  watch:{
    id(){
      this.getActiveUser();
    },
    '$store.state.chat.messages'(){
      this.$nextTick(()=>{
        this.scrollLastMessageIntoView();
      });
}
  },
  created() {
    this.loadingMessages=true;
    this.getActiveUserPagable();
    this.$nextTick(()=>{
      this.scrollLastMessageIntoView();
    });
  },
  methods: {
    insert(emoji) {
      console.log(emoji);
      this.newMessage += emoji.data;
    },
    scrollLastMessageIntoView(){
      let objDiv = this.$refs.chat.$el
      objDiv.scrollTop = objDiv.scrollHeight;
    },
    findMessages(){

      if (this.$store.state.chat.activeContact === undefined || !this.$store.state.chat.activeContact) return;
      findChatMessages(this.$store.state.chat.activeContact.id, this.currentUser.id).then((msgs) =>{
        this.$store.dispatch("chat/messages",msgs);
        this.loadingMessages=false;
            loadContacts();
      }
      );
    },
    loadNextMessages(){
      this.loadedMessagesPage++;
      this.findMessagesByPage(this.loadedMessagesPage);
    },
    findMessagesByPage(page){

      if (this.$store.state.chat.activeContact === undefined || !this.$store.state.chat.activeContact) return;
      findChatMessagesPagable(this.$store.state.chat.activeContact.id, this.currentUser.id,page).then((msgs) =>{
            this.$store.dispatch("chat/messages",msgs);
            this.loadingMessages=false;
            loadContacts();
          }
      );
    },
    getActiveUser(){
      let self=this;
      ApiService.get("user/get?id="+this.id).then(response => {
        self.$store.state.chat.activeContact=response.data;
        self.findMessages();
      })
    },
    getActiveUserPagable(){
      let self=this;
      ApiService.get("user/get/simple/"+this.id).then(response => {
        self.$store.state.chat.activeContact=response.data;
        self.findMessagesByPage(this.loadedMessagesPage);
      })
    },
    sendMessage(msg){
      if(!this.$store.state.chat.connectionStatus){
        this.$store.dispatch("notificationsStore/add",new Notification("error","You are offline. Please try to refresh.",false))
        return false;
      }
      if (msg.trim() !== "") {
        const message = {
          senderId: this.currentUser.id,
          recipientId: this.$store.state.chat.activeContact.id,
          senderName: this.currentUser.name,
          recipientName: this.$store.state.chat.activeContact.name,
          content: msg,
          timestamp: new Date(),
        };
        console.log("Message "+JSON.stringify(message))
        this.$store.state.chat.stompClient.send("/app/chat", {}, JSON.stringify(message));

        this.newMessages = [...this.$store.state.chat.messages];
        this.newMessages.push(message);
        this.$store.dispatch("chat/messages",this.newMessages);
        this.newMessage="";
      }
    },
    messageTime(time){
      let moment = require('moment'); // require
      return moment(time).fromNow();
    },
  },
  computed:{
    currentUser(){
      return this.$store.state.auth.user;
    }
  }
}
</script>

<style scoped>
.chatHeight{
  height: calc(100vh - 60px - 60px - 80px)!important;
}
@media screen and (max-width: 600px) {
.chatHeight{
  height: calc(100vh - 60px - 60px - 80px)!important;
}
}

</style>