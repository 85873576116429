<template>
<v-btn x-small :loading="loading" @click="clear">Clear Event Cache</v-btn>
</template>

<script>
import {clearCache} from "@/services/cache.service";

export default {
  name: "ClearCache",
  data(){
    return{
      loading:false,
    }
  },
  methods:{
    async clear() {
      this.loading=true;
      await clearCache();
      this.loading=false;
    }
  }
}
</script>

<style scoped>

</style>