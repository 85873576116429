import {createFeed, deleteFeed, getMyFeed} from "@/services/lobby.service";
export const feed = {
    namespaced: true,
    state: {
        items:[],
        page:0,
        endOfList:false
        },
    actions: {
        async deleteItem({commit},id){
          let response = await deleteFeed(id);
          if(response.status === 200) commit("deleteItem",id)
        },
        async createItem({commit},feed){
            let response = await createFeed(feed);
            if(response){
                commit("addItem",response);
            }
        },
        async loadFeedItems({commit}) {
            let response = await getMyFeed(0);
            commit("setFeedItems", response);
        },
        async loadCurrentPage({commit}){

            commit("loadPage");
        }
    },

    mutations: {
        async loadPage(state) {
            if(state.endOfList) return null;
            console.log("Page", state.page);
            let items = await getMyFeed(state.page);
            console.log("items",items);
            state.items = state.items.concat(items);
            if(items.length<10) state.endOfList = true;
            state.page++;
        },
        addItem(state,item){
            state.items.push(item);
        },
        deleteItem(state,index){
            console.log("Deleting item",index);
          state.items.splice(state.items.findIndex(x => x.id===index),1);
        },
        addItems(state,items){
          state.items.concat(items);
          if(items.length<10) state.endOfList = true;
          state.page++;
        },
        setFeedItems(state,items){
            if(items.length<10) state.endOfList = true;
            state.items=items;
            state.page=1;
        }

    }
};